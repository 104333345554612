import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SystemSettings {
  defaultPlatformLanguage: string;
  ianaTimeZone: string;
  platformLanguages: PlatformLanguages[];
  otpBasedLogin: boolean;
  ssoCatelogueOtpBasedLogin: boolean;
  otpBasedActivation: boolean;
  preloginAccess: any;
  isGeoTagEnabled: boolean;
  cookieConcentEnabled: boolean;
  cookieConcentExpiryInDays: any;
  cookieConcentIsEmeaRegion: boolean;
}

export class CatalogueReferences {
  catalogueId: string;
  isEnableExperience: boolean;
  isEnableMerchandise: boolean;
  isEnableMastery: boolean;
  isoCode: string;
  programNumber: string;
}

export class PlatformLanguages {
  languageCulture: string;
  languageName: string;
}

export class PlatformSystemSettings {
  catalogueReferences: CatalogueReferences[];
  defaultPlatformLanguage: string;
  ianaTimeZone: string;
  platformLanguages: PlatformLanguages[];
  showPointBalanceInPlatform: boolean;
  otpBasedLogin: boolean;
  ssoCatelogueOtpBasedLogin: boolean;
  otpBasedActivation: boolean;
  isFIFOEnabled: boolean;
  showExpiredData: boolean;
  mostRedeemedDefaultId: string;
  preloginAccess: any;
  isCashRedemptionEnabled: boolean;
  crBaseUrl: string;
  isWalletBankEnabled: boolean;
  cookieConcentEnabled: boolean;
  cookieConcentExpiryInDays: any;
  cookieConcentIsEmeaRegion: boolean;
}
