import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';import { UrlBuilder } from '@app/core/core-api-variables';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(public toastr: ToastrService, public router: Router, public translateService: TranslateService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((e: HttpErrorResponse) => {
            return throwError(() => this.errorHandler(req, e))
        }));
    }

    errorHandler(req: HttpRequest<any>, errorResponse: HttpErrorResponse) {
        if (errorResponse.message.indexOf('accounts/Register:') != -1 ) {
            if (errorResponse?.error?.errorCode) {
                const errMsg = errorResponse?.error?.dynamicValues ?
                UrlBuilder(this.translateService.instant('AccountRegister.'+errorResponse?.error?.errorCode), errorResponse?.error?.dynamicValues) :
                 this.translateService.instant('AccountRegister.'+errorResponse?.error?.errorCode);
                this.toastr.error(errMsg);
            }
        }
        else if ((errorResponse.status === 500 || errorResponse.status === 401 || errorResponse.status === 400) &&
            errorResponse.error && errorResponse.error.errors) {
            if (errorResponse.error.errors.length > 1) {
                let error = errorResponse.error.errors.map(x => { return `${x.ErrorMessage}<br/>` })
                this.toastr.error(error, null, { enableHtml: true });
            }
        }
        else {
            if (errorResponse.status !== 404 && errorResponse.status !== 500) {
                if (errorResponse?.error?.errorCode) {

                }
                else if (errorResponse.error && errorResponse.error.modelState) {
                    let error = '';
                    Object.keys(errorResponse.error.modelState).forEach(key => {
                        let errorKey = key.charAt(0).toUpperCase() + key.slice(1)
                        error = `${error}${this.translateService.instant(errorKey)}<br/>`;
                    });
                    this.toastr.error(error, null, { enableHtml: true });
                }
                else if (errorResponse.error && errorResponse.error.error && errorResponse.error.error_description) {
                    this.toastr.error(this.translateService.instant(errorResponse.error.error));
                }
            }
        }
        return errorResponse;
    }
}
