<ng-container>
  <div class="edit-profile">
    <form [formGroup]="editProfile" *ngIf="editProfile">

      <div class="bg-color3 parent" #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this)">
        <div class="content-width form-content bg-color2">
          <p class="sub-heading margin-bottom-20">{{ 'ParticipantActivation.PleaseFillYourDetails'
            | translate }}</p>

          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100 pb-3">
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.ProfilePicture' | translate }}
              </label>
              <div class="form-image-uploader align-items-center justify-content-center">
                <img alt="Profile Image" [src]="file" class="image-placeholder">
                <input class="form-control m-2 input-file-custom" type="file" accept="image/*" (change)="onChange($event)">
              </div>
            </div>
            <div class="">
            </div>
          </div>

          <div class="grid grid-gap-10 grid-col-hd-50 grid-col-xs-100">
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.FirstName' | translate }} <span
                  class="mandatory">*</span></label>
              <form-input controlName="firstName" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.FirstName' | translate }}">
              </form-input>
              <div class="help-block text-danger"
                *ngIf="!editProfile.controls.firstName.valid && (editProfile.controls.firstName.touched )">
                <span [hidden]="!editProfile.controls['firstName'].hasError('required')"
                  translate="ParticipantActivation.FirstNameRequired"></span>
              </div>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.LastName' | translate }} <span
                  class="mandatory">*</span></label>
              <form-input controlName="lastName" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.LastName' | translate }}">
              </form-input>
              <div class="help-block text-danger"
                *ngIf="!editProfile.controls.lastName.valid && (editProfile.controls.lastName.touched )">
                <span [hidden]="!editProfile.controls['lastName'].hasError('required')"
                  translate="ParticipantActivation.LastNameRequired"></span>
              </div>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.Username' | translate }}
              </label>
              <form-input controlName="userName" disable="true" theme="theme2" placeHolder="UserName">
              </form-input>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.Email' | translate }} <span
                  class="mandatory">*</span></label>
              <form-input controlName="email" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Email' | translate }}">
              </form-input>
              <div class="help-block text-danger"
                *ngIf="!editProfile.controls.email.valid && (editProfile.controls.email.touched )">
                <span [hidden]="!editProfile.controls['email'].hasError('required')"
                  translate="ParticipantActivation.EmailInvalid"></span>
              </div>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.MobilePhoneNumber' | translate
                }}</label>
              <form-input controlName="phone" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.MobilePhoneNumber' | translate }}">
              </form-input>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.JobRole' | translate }}
              </label>
              <form-input controlName="role" disable="true" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.JobRole' | translate }}">
              </form-input>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.OrganisationName' | translate
                }}</label>
              <form-input controlName="organisationName" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.OrganisationName' | translate }}">
              </form-input>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.Address1' | translate }}
              </label>
              <form-input controlName="address1" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Address1' | translate }}">
              </form-input>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.Town' | translate }}
              </label>
              <form-input controlName="town" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Town' | translate }}">
              </form-input>
            </div>
            <div class="">
              <label class="padding-h-5">{{ 'ParticipantActivation.Telephone' | translate }}
              </label>
              <form-input controlName="telephone" theme="theme2"
                placeHolder="{{ 'ParticipantActivation.Telephone' | translate }}">
              </form-input>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-center">
            <div class="">
              <div class="margin-top-30">
                <form-button text="{{ 'ParticipantActivation.Submit' | translate }}" class="form-button-custom" theme="theme5" size="stretch"
                  (click)="save(editProfile)"></form-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>