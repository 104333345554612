import { LOCATION_INITIALIZED } from "@angular/common";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "@env/environment";
import { SystemSettings } from "@core/models";
import { firstValueFrom } from "rxjs";

@Injectable()
export class StartupService {
  constructor(
    public http: HttpClient,
    public _systemSettings: SystemSettings,
    public translate: TranslateService,
    public injector: Injector
  ) {}

  load(): Promise<SystemSettings> {
    const url = environment.baseUrl + environment.version;
    return firstValueFrom(
      this.http
        .get<SystemSettings>(`${url}systemsettings/current/platform`)
        .pipe(
          map((settings: any) => {
            this._systemSettings.defaultPlatformLanguage =
              settings.defaultPlatformLanguage;
            this._systemSettings.ianaTimeZone = settings.ianaTimeZone;
            this._systemSettings.platformLanguages = settings.platformLanguages;
            this._systemSettings.otpBasedLogin = settings.otpBasedLogin;
            this._systemSettings.ssoCatelogueOtpBasedLogin =
              settings.ssoCatelogueOtpBasedLogin;
            this._systemSettings.otpBasedActivation =
              settings.otpBasedActivation;
            this._systemSettings.preloginAccess = settings.preloginAccess;
            this._systemSettings.isGeoTagEnabled = settings.isGeoTagEnabled;
            this._systemSettings.cookieConcentEnabled =
              settings.cookieConcentEnabled;
            this._systemSettings.cookieConcentExpiryInDays =
              settings.cookieConcentExpiryInDays;
            this._systemSettings.cookieConcentIsEmeaRegion =
              settings.cookieConcentIsEmeaRegion;

            this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            let culture = localStorage.getItem("culture");
            if (culture) {
              this.translate.use(culture);
            } else {
              this.translate.use(settings.defaultPlatformLanguage);
              localStorage.setItem("culture", settings.defaultPlatformLanguage);
            }

            if (localStorage.getItem("platformSettings"))
              localStorage.removeItem("platformSettings");

            localStorage.setItem(
              "platformSettings",
              JSON.stringify(this._systemSettings)
            );
            return settings;
          })
        )
    );
  }
}
