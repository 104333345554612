import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'account-register-confirmation-modal',
    templateUrl: './confirmation.modal.component.html',
    styleUrls: ['./confirmation.modal.component.scss']
})
export class AccountRegisterConfirmationModalComponent implements OnInit {

    @Input() message: string = '';

    constructor(private activeModal: NgbActiveModal, public router: Router) { }

    ngOnInit() {
    }
    ok() {
        this.router.navigate([`/`]);
        this.activeModal.dismiss();
    }
}
