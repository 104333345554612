import { Routes } from '@angular/router';
import {
    LoginComponent,
    ForgotPasswordComponent,
    AccountActivationComponent,
    ExpiredPasswordComponent,
    ResetPasswordComponent,
    AccountRegisterComponent,
    SiteMonitoringComponent,
    PreloginLegalPageComponent
} from './accounts';
import { RegisterPageGuard } from '../shared/guards/register.page.component.guard';

export const PreloginRoutes: Routes = [
    {
        path: '',
        component: LoginComponent,
        data: { title: 'PageTitles.Login', isPreLogin: true }
    },
    {
        path: 'login',
        component: LoginComponent,
        //pathMatch: 'full',
        data: { title: 'PageTitles.Login', isPreLogin: true }
    },
    {
        path: 'forgot',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.ForgotPassword', isPreLogin: true }
    },
    {
        path: 'account/ResetPassword',
        component: ResetPasswordComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.ResetPassword', isPreLogin: true }
    },
    {
        path: 'account/resetpassword/:token',
        component: ResetPasswordComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.ResetPassword', isPreLogin: true }
    },
    {
        path: 'register',
        component: AccountRegisterComponent,
        canActivate: [RegisterPageGuard],
        pathMatch: 'full',
        data: { title: 'PageTitles.Register', isPreLogin: true }
    },
    {
        path: 'activate',
        component: AccountActivationComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.ActivateParticipant', isPreLogin: true }
    },
    {
        path: 'sitemonitoring',
        component: SiteMonitoringComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.SiteMonitoring', isPreLogin: true }
    },
    {
        path: 'account/expiredpassword/:username',
        component: ExpiredPasswordComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.ExpiredPassword', isPreLogin: true }
    },
    {
        path: 'legal/:page',
        component: PreloginLegalPageComponent,
        pathMatch: 'full',
        data: { title: 'PageTitles.LegalPage', isPreLogin: true }
    }
];