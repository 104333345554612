import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from '@core/startup';
import { PreloginService } from '@app/core/prelogin/services';

@Injectable()
export class RegisterPageGuard implements CanActivate {
    constructor(public router: Router, public authService: AuthenticationService,
        public PreloginService: PreloginService,
    ) { }

    canActivate() {
        const selfRegistrationStatus = localStorage.getItem('selfregistrationStatus') && (JSON.parse(localStorage.getItem('selfregistrationStatus'))?.isEmeaEnabled ||
            JSON.parse(localStorage.getItem('selfregistrationStatus'))?.isEnabled);
        if (selfRegistrationStatus) {
            return true;
        } else {
            this.PreloginService.getSelfRegistrationStatus().subscribe(response => {
                localStorage.setItem('selfregistrationStatus', JSON.stringify(response));
                if (response?.isEnabled || response?.isEmeaEnabled) {
                    return true;
                }
            });
        }
        this.router.navigate(['/']);
        return false;
    }
}