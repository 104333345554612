import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";
import * as fromStore from "@core/store";
import * as fromAuthentication from "@core/store/actions";

@Injectable()
export class AuthenticationService {
  constructor(
    public http: HttpClient,
    public router: Router,
    private store: Store<fromStore.PlatformState>
  ) {}

  getToken() {
    let token = JSON.parse(localStorage.getItem("token"));
    if (token && token.access_token) {
      return token.access_token;
    }
    return undefined;
  }

  getRefreshToken() {
    let currentUser = JSON.parse(localStorage.getItem("token"));
    if (currentUser && currentUser.refresh_token) {
      return currentUser.refresh_token;
    }
    return undefined;
  }

  checkUser() {
    let token = localStorage.getItem("token");
    if (!token) {
      token = sessionStorage.getItem("token");
    }
    if (token) {
      const user = JSON.parse(token);
      this.store.dispatch({
        type: fromAuthentication.CHANGE_AUTHENTICATION_STATUS,
        payload: true,
      });
      return user;
    }
    return undefined;
  }

  login(
    username: string,
    password: string,
    loginType: string,
    granttype: string,
    referenceID: string,
    isOTP: boolean
  ) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set("Nexus-Environment-Key", environment.environmentKey);
    let body = new HttpParams()
      .set("username", username)
      .set("password", password)
      .set("type", loginType)
      .set("client_id", "nexusWeb")
      .set("grant_type", granttype);

    if (isOTP)
      body = new HttpParams()
        .set("username", username)
        .set("password", password)
        .set("type", loginType)
        .set("client_id", "nexusWeb")
        .set("otp", password)
        .set("loginid", username)
        .set("referenceID", referenceID)
        .set("grant_type", granttype);
    return this.http
      .post<any>(`${environment.baseUrl}oauth/token`, body.toString(), {
        headers: headers,
      })
      .pipe(
        map((token) => {
          if (token && token.access_token) {
            localStorage.setItem("token", JSON.stringify(token));
            sessionStorage.setItem("token", JSON.stringify(token));
            this.store.dispatch({
              type: fromAuthentication.CHANGE_AUTHENTICATION_STATUS,
              payload: true,
            });
          }
          return token;
        })
      );
  }

  impersonateLogin(token) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set("Nexus-Environment-Key", environment.environmentKey);
    let body = new HttpParams({ encoder: new CustomEncoder() })
      .set("token", token)
      .set("client_id", "nexusWeb")
      .set("grant_type", "impersonate");

    return this.http
      .post<any>(`${environment.baseUrl}oauth/token`, body.toString(), {
        headers: headers,
      })
      .pipe(
        map((token) => {
          if (token && token.access_token) {
            localStorage.setItem("token", JSON.stringify(token));
            sessionStorage.setItem("token", JSON.stringify(token));
            this.store.dispatch({
              type: fromAuthentication.CHANGE_AUTHENTICATION_STATUS,
              payload: true,
            });
            this.store.dispatch({
              type: fromAuthentication.CHANGE_IMPERSONATE_STATUS,
              payload: true,
            });
          }
          return token;
        })
      );
  }

  logout() {
    sessionStorage.removeItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("platformSettings");
    const cookieConsent = JSON.parse(
      localStorage.getItem("cookieConsent") || "{}"
    );

    if (cookieConsent.status === "rejected") {
      localStorage.removeItem("cookieConsent");
    }
    this.store.dispatch({
      type: fromAuthentication.CHANGE_AUTHENTICATION_STATUS,
      payload: false,
    });
    this.store.dispatch({
      type: fromAuthentication.CHANGE_IMPERSONATE_STATUS,
      payload: false,
    });
    this.store.dispatch({
      type: fromAuthentication.UPDATE_USER,
      payload: null,
    });
    this.router.navigateByUrl("/", { replaceUrl: true });
  }

  refreshToken(refresh_token) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set("Nexus-Environment-Key", environment.environmentKey);
    const body = new HttpParams()
      .set("refresh_token", refresh_token)
      .set("grant_type", "refresh_token")
      .set("client_id", "nexusWeb");

    return this.http
      .post<any>(`${environment.baseUrl}oauth/token`, body.toString(), {
        headers: headers,
      })
      .pipe(
        map((token) => {
          return token;
        })
      );
  }

  getCurrentUser(): Observable<any> {
    const url = `${
      environment.baseUrl + environment.version
    }accounts/users/current`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPointsBalance(): Observable<any> {
    const url = `${
      environment.baseUrl + environment.version
    }accounts/users/current/points`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUserAccount(
    loginId: string,
    loginType: string,
    mode: string
  ): Observable<any> {
    const url =
      `${
        environment.baseUrl + environment.version
      }accounts/getuseraccount?loginId=` +
      loginId +
      `&loginType=` +
      loginType +
      `&mode=` +
      mode;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  sendOTP(requestType: string, userId: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set("Nexus-Environment-Key", environment.environmentKey);
    const body = {
      requestType: requestType,
      userId: userId,
    };
    return this.http
      .post<any>(
        `${environment.baseUrl + environment.version}otpservice/createotp`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getGlobalMenuAccess() {
    const url = `${
      environment.baseUrl + environment.version
    }globalmenuaccess/menu`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getFileXLS(): Observable<object> {
    return this.http.get(`${environment.baseUrl + environment.version}`, {
      responseType: "arraybuffer",
    });
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
