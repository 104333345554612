import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faXmarkCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Observable, OperatorFunction, switchMap } from 'rxjs';
import { AccountRegisterService } from '../services/account-register.services';

@Component({
    selector: 'app-organisation-search-add',
    templateUrl: './organisation.search.add.component.html',
    styleUrls: ['./organisation.search.add.component.scss']
})
export class OrganisationSearchAddComponent implements OnInit {
    @Input() countryList = [];
    public faXmarkCircle = faXmarkCircle;
    public faInfoCircle = faInfoCircle;
    public searchOrganisationInfo: any = {};
    public showSearchOrganisationInfo: boolean = false;
    public isSubmitClicked: boolean = false;
    public showAddOrganisationForm: boolean = false;
    public createOrganisationForm: FormGroup;
    public searchOrganisationForm: FormGroup;
    postalCodeList: any = [
        { name: 'India', value: 'IND', regexPostalCode: /^[0-9]{6}$/ },
        { name: 'US', value: 'USA', regexPostalCode: /^[0-9]{5}$/ },
        { name: 'Australia', value: 'Australia', regexPostalCode: /^[0-9]{4}$/ },
        { name: 'UK', value: 'UK', regexPostalCode: /[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}/i },
    ];
    constructor(public activeModal: NgbActiveModal, public accountRegisterSerivces: AccountRegisterService) {
        this.searchOrganisationForm = new FormGroup({
            searchText: new FormControl('')
        });
        this.createOrganisationForm = new FormGroup({
            address1: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            address2: new FormControl('', [Validators.maxLength(50), Validators.minLength(0)]),
            // code: new FormControl('', [Validators.maxLength(50), Validators.required]),
            country: new FormControl('', [Validators.maxLength(50), Validators.required]),
            name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
            town: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            postCode: new FormControl('', [Validators.required, Validators.maxLength(6), Validators.pattern(/^\d{6}$/)]) // format validation pending
        });
    }
    ngOnInit(): void {
        this.createOrganisationForm.controls.country.valueChanges.subscribe(value => {
            const obj = this.postalCodeList.find(x => x.value == value);
            if (obj?.regexPostalCode) {
                this.createOrganisationForm.controls.postCode.setValidators([Validators.required, Validators.pattern(new RegExp(obj?.regexPostalCode))]);
                this.createOrganisationForm.controls.postCode.updateValueAndValidity();
            }
        })
    }
    searchOrganisation() {
        const value = this.searchOrganisationForm.getRawValue()?.searchText;
        if (value?.name && value?.postCode) {
            this.isSubmitClicked = false;
            this.showAddOrganisationForm = false;
            this.showSearchOrganisationInfo = true;
            this.searchOrganisationInfo = this.searchOrganisationForm.getRawValue()?.searchText;
        }
    }
    addOrganisation() {
        this.searchOrganisationForm.reset();
        this.isSubmitClicked = false;
        this.showAddOrganisationForm = true;
        this.showSearchOrganisationInfo = false;
    }
    submit() {
        this.isSubmitClicked = true;
        if (this.showAddOrganisationForm) {
            if (this.createOrganisationForm?.valid) {
                this.activeModal.close(this.createOrganisationForm.getRawValue())
            }
        }
        else {
            if (this.searchOrganisationInfo?.postCode && this.searchOrganisationInfo?.name) {
                this.activeModal.close(this.searchOrganisationInfo);
            }
        }
    }
    productFormatter = (result: any) => result.name;
    productInputFormatter = (result: any) => result.name;
    organisationSearch: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(term => term.length < 3 ? [] :
                this.accountRegisterSerivces.getOrganisations({ globalSearch: term })
            )
        )
}
