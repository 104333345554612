<div [style]="showPopup ? 'display:block;' : 'display:none;'">
  <div
    class="cookie-modal-container"
    [class]="showPopup ? 'close-slide-up' : 'close-slide-down'"
  >
    <div class="margin-bottom-20 icon-container">
      <img src="assets/icons/cookie-policy-icon.png" alt="" />
    </div>
    <div class="margin-bottom-10">
      <strong>{{ "cookiePolicy.Title" | translate }}</strong>
    </div>
    <div
      class="margin-bottom-20"
      [innerHTML]="'cookiePolicy.Description' | translate"
      (click)="handleLinkClick($event)"
    ></div>

    <div class="cookie-button-container">
      <button
        style="flex: 1"
        class="btn btn-primary btn-full-width"
        (click)="acceptAllcookie()"
      >
        {{ "cookiePolicy.Accept" | translate }}
      </button>
      <button
        *ngIf="this.systemSettings.cookieConcentIsEmeaRegion"
        style="flex: 1"
        class="btn btn-danger btn-full-width btn-full-width-reject"
        (click)="rejectCookies()"
      >
        {{ "cookiePolicy.Reject" | translate }}
      </button>
    </div>
    <button
      *ngIf="!this.systemSettings.cookieConcentIsEmeaRegion"
      class="btn btn-primary btn-full-width btn-full-width-reset"
      (click)="openModel(content)"
    >
      {{ "cookiePolicy.Customize" | translate }}
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ "cookiePolicy.cookieModel.Title" | translate }}
    </h4>
    <span class="cross-icon" (click)="closeModel()">X</span>
  </div>
  <div class="modal-body">
    <div class="margin-bottom-20">
      {{ "cookiePolicy.cookieModel.Description" | translate }}
    </div>
    <div class="flex-space-between">
      <label for="functional"
        ><strong>{{
          "cookiePolicy.cookieModel.Functional" | translate
        }}</strong></label
      >
      <app-toggle-switch-component
        (changed)="functional = $event"
        [selected]="true"
        [disabled]="true"
        id="functional"
      >
      </app-toggle-switch-component>
    </div>
    <div class="flex-space-between">
      <label for="functional"
        ><strong>{{ "cookiePolicy.cookieModel.Tracking" | translate }}</strong>
      </label>
      <app-toggle-switch-component
        (changed)="tracking = $event"
        [selected]="tracking"
        id="functional"
      >
      </app-toggle-switch-component>
    </div>
    <div class="flex-space-between">
      <label for="functional">
        <strong>
          {{ "cookiePolicy.cookieModel.Performance" | translate }}
        </strong>
      </label>
      <app-toggle-switch-component
        (changed)="performance = $event"
        [selected]="performance"
        id="functional"
      >
      </app-toggle-switch-component>
    </div>
    <div class="flex-space-between">
      <label for="functional">
        <strong>
          {{ "cookiePolicy.cookieModel.Personalization" | translate }}
        </strong>
      </label>
      <app-toggle-switch-component
        (changed)="personalization = $event"
        [selected]="personalization"
        id="functional"
      >
      </app-toggle-switch-component>
    </div>
  </div>
  <!-- Commenting this following line since i was not sure about this requirement. -->
  <!--input type="checkbox" class="custom-control-input" id="customSwitches" /-->
  <div class="modal-footer">
    <button type="button" class="btn custom-btn-light" (click)="submitCookie()">
      {{ "cookiePolicy.cookieModel.Confirm" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary customBtnReset-cookie"
      (click)="closeModel()"
    >
      {{ "cookiePolicy.cookieModel.Cancel" | translate }}
    </button>
  </div>
</ng-template>
