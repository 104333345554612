import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { HttpService } from "./http.service";
import {
  UrlBuilder,
  USER_RESOLVING_AUDIENCE,
  GET_CLAIM_CONFIGURATION,
  GET_GAME_TOKEN,
  MOST_REDEEM_PRODUCTS,
  GET_PROFILE,
  GET_COOKIE_POLICY,
  SET_COOKIE_POLICY,
  GET_USER_IMAGE,
  GET_MESSAGE_ARTICLES,
  IS_COOKIE_EXISTS,
} from "@core/core-api-variables";
import { PlatformSystemSettings } from "@app/core/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(public http: HttpService) {}

  containsUser(resolvingAudienceId, userId) {
    let data = { resolvingAudienceId, userId };
    let url = UrlBuilder(USER_RESOLVING_AUDIENCE, data);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getClaimConfiguration(claimFormId) {
    let data = { claimFormId };
    let url = UrlBuilder(GET_CLAIM_CONFIGURATION, data);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getGameToken() {
    return this.http.get(GET_GAME_TOKEN).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMostRedeemProducts(noOfItems) {
    let data = { noOfItems };
    let url = UrlBuilder(MOST_REDEEM_PRODUCTS, data);
    return this.http.get(url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getProfile() {
    let url = GET_PROFILE;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUserImage(userId) {
    let data = { userId };
    let url = UrlBuilder(GET_USER_IMAGE, data);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  isCookieExists() {
    return this.http.get(IS_COOKIE_EXISTS, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getCookies() {
    return this.http.get(GET_COOKIE_POLICY, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setCookies(data) {
    return this.http
      .post(SET_COOKIE_POLICY, data, { withCredentials: true })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getCarouselProduct(messagePageId: string, culture: string) {
    let data = { messagePageId, culture };
    let url = UrlBuilder(GET_MESSAGE_ARTICLES, data);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPlatformSettings(): Observable<PlatformSystemSettings> {
    return this.http.get(`platformSettings/current`).pipe(
      map((settings: any) => {
        let platformSettings = new PlatformSystemSettings();
        platformSettings.catalogueReferences = settings.catalogueReferences;
        platformSettings.defaultPlatformLanguage =
          settings.defaultPlatformLanguage;
        platformSettings.ianaTimeZone = settings.ianaTimeZone;
        platformSettings.platformLanguages = settings.platformLanguages;
        platformSettings.showPointBalanceInPlatform =
          settings.showPointBalanceInPlatform;
        platformSettings.otpBasedLogin = settings.otpBasedLogin;
        platformSettings.ssoCatelogueOtpBasedLogin =
          settings.ssoCatelogueOtpBasedLogin;
        platformSettings.otpBasedActivation = settings.otpBasedActivation;
        platformSettings.isFIFOEnabled = settings.isFIFOEnabled;
        platformSettings.mostRedeemedDefaultId = settings.mostRedeemedDefaultId;
        platformSettings.showExpiredData = settings.showExpiredData;
        platformSettings.preloginAccess = settings.preloginAccess;
        platformSettings.isCashRedemptionEnabled =
          settings.isCashRedemptionEnabled;
        platformSettings.crBaseUrl = settings.crBaseUrl;
        platformSettings.isWalletBankEnabled = settings.isWalletBankEnabled;
        platformSettings.cookieConcentEnabled = settings.cookieConcentEnabled;
        platformSettings.cookieConcentExpiryInDays =
          settings.cookieConcentExpiryInDays;
        platformSettings.cookieConcentIsEmeaRegion =
          settings.cookieConcentIsEmeaRegion;

        if (localStorage.getItem("platformSettings"))
          localStorage.removeItem("platformSettings");

        localStorage.setItem(
          "platformSettings",
          JSON.stringify(platformSettings)
        );

        return platformSettings;
      })
    );
  }
}
