import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../..";
import { MessageService } from "../../services/message.service";
import { PlatformSystemSettings } from "@core/models";
import { AuthenticationService } from "@app/core/startup";
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: "app-cookie-component",
  templateUrl: "./cookie-component.component.html",
  styleUrls: ["./cookie-component.component.scss"],
})
export class CookieComponentComponent implements OnInit {
  public selected: boolean = false;
  public checked: boolean;
  public functional: boolean = true;
  public tracking: boolean = false;
  public performance: boolean = false;
  public personalization: boolean = false;
  public showPopup: boolean = false;
  systemSettings: PlatformSystemSettings;
  private routeSubscription: Subscription;
  public modalRef: any;
  public isCustomizePopupOpened: boolean = false;
  cookieData: object;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private cookieService: CookieService,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem("platformSettings")) {
      this.systemSettings = JSON.parse(
        localStorage.getItem("platformSettings")
      );

      if (this.systemSettings.cookieConcentEnabled) {
        this.routeSubscription = this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe(() => {
            this.checkRouteForPopup();
          });

        this.checkRouteForPopup();
      }
    }
  }

  checkRouteForPopup(): void {
    const cookieConsent = JSON.parse(
      localStorage.getItem("cookieConsent") || "{}"
    );
    const currentRoute = this.router.url.split("?")[0].split("#")[0];
    if (
      cookieConsent.status === "rejected" ||
      cookieConsent.status === "accepted" ||
      cookieConsent.status === "preAccepted" ||
      !(currentRoute === "/" || currentRoute === "/home")
    ) {
      this.showPopup = false;
    } else {
      this.showPopup = true;
    }
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  openModel(content: any): void {
    this.isCustomizePopupOpened = true;
    this.showPopup = false;
    this.modalRef = this.modalService.open(content, {
      centered: true,
      modalDialogClass: "cookie-modal",
    });
  }

  closeModel() {
    this.tracking = false;
    this.performance = false;
    this.personalization = false;
    this.modalRef.close();
    this.showPopup = true;
    this.isCustomizePopupOpened = false;
  }
  toggle() {
    this.selected = this.checked;
  }

  submitCookie() {
    const expireDate = new Date();
    const expiryDays = this.systemSettings.cookieConcentExpiryInDays || 365;
    expireDate.setDate(expireDate.getDate() + expiryDays);
    const expireTime = expireDate.getTime();

    const cookieConsent = {
      status: "preAccepted",
      expireTime,
      preferences: {
        functional: true,
        tracking: this.tracking,
        performance: this.performance,
        personalization: this.personalization,
      },
    };

    if (!this.authService.checkUser()) {
      localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
      this.messageService.showSuccess(
        this.translate.instant("cookiePolicy.savedSuccessfully")
      );
      this.showPopup = false;
      this.modalRef.close();
    } else {
      this.sharedService.getCookies().subscribe((res: any) => {
        if (!res.isUserAcceptedCookiePolicy) {
          this.cookieData = {
            contentPageId: res.contentPage.id,
            version: res.contentPage.version,
            ...cookieConsent.preferences,
          };
          cookieConsent.status = "accepted";
          localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
          this.sharedService
            .setCookies(this.cookieData)
            .subscribe(() => undefined);
        } else {
          cookieConsent.status = "accepted";
          localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
        }
      });
      this.messageService.showSuccess(
        this.translate.instant("cookiePolicy.savedSuccessfully")
      );
      this.showPopup = false;
      this.modalRef.close();
    }
  }

  acceptAllcookie(): void {
    const expireDate = new Date();
    const expiryDays = this.systemSettings.cookieConcentExpiryInDays || 365;
    expireDate.setDate(expireDate.getDate() + expiryDays);
    const expireTime = expireDate.getTime();

    const cookieConsent = {
      status: "preAccepted",
      expireTime,
      preferences: {
        functional: true,
        tracking: true,
        performance: true,
        personalization: true,
      },
    };
    if (!this.authService.checkUser()) {
      this.showPopup = false;
      localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
      this.messageService.showSuccess(
        this.translate.instant("cookiePolicy.savedSuccessfully")
      );
    } else {
      this.sharedService.getCookies().subscribe((res: any) => {
        if (!res.isUserAcceptedCookiePolicy) {
          this.cookieData = {
            contentPageId: res.contentPage.id,
            version: res.contentPage.version,
            ...cookieConsent.preferences,
          };
          cookieConsent.status = "accepted";
          localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
          this.sharedService
            .setCookies(this.cookieData)
            .subscribe(() => undefined);
        } else {
          cookieConsent.status = "accepted";
          localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
        }
      });
      this.messageService.showSuccess(
        this.translate.instant("cookiePolicy.savedSuccessfully")
      );
      this.showPopup = false;
    }
  }

  rejectCookies() {
    const cookieConsent = { status: "rejected" };

    localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));

    this.messageService.showSuccess(
      this.translate.instant("cookiePolicy.rejectedSuccessfully")
    );
    this.showPopup = false;
  }

  handleLinkClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains("cookie-link")) {
      event.preventDefault();
      if (this.authService.checkUser()) {
        window.open("/legalpage/cookies", "_blank");
      } else {
        window.open("/legal/cookies", "_blank");
      }
    }
  }
}
