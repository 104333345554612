import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService, AlertService } from '@core/startup';
import { MessageService } from '@core/shared/services/message.service';
import { LoginType, RequestType } from '@core/common/constants.service';
import { SystemSettings } from '@core/models'
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { environment } from '@env/environment';
import * as fromStore from '@core/store';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.scss'
    ]
})
export class LoginComponent implements OnInit, OnDestroy {

    public formLogin: FormGroup;
    public referenceID: any;
    public isResendOTP: boolean = false;
    public isPasswordEnabled: boolean = true;
    public isOTPEnabled: boolean = false;
    public loginTypes: any = LoginType.loginTypes;
    public label: string = 'ParticipantLogin.Username';
    public validationError: string = 'ParticipantLogin.UsernameRequired';
    public passwordLable: string = 'ParticipantLogin.Password'
    public passwordErrorMessage: string = 'ParticipantLogin.PasswordRequired';
    public validationMobileNumberError = 'ParticipantLogin.EnterMobileNumber';
    public expiryTime: string;
    public expiryMinutes: any;
    public expirySeconds: any;
    public isSelfRegistrationRequired: any;
    public isSelfRegistrationEnabled: boolean = false;
    public disableUserName: boolean = true;
    isSubmitted: boolean;
    private onDestroy$ = new Subject<void>();
    isAuthenticated: boolean;
    isMobileNumber :Boolean = false;
    latitude: number;
    longitude: number;
    constructor(
        public fb: FormBuilder,
        public router: Router,
        public authenticationService: AuthenticationService,
        public alertService: AlertService,
        private messageService: MessageService,
        public translate: TranslateService,
        public systemSettings: SystemSettings,
        public PreloginService: PreloginService,
        private store: Store<fromStore.PlatformState>,
        private activeRouter: ActivatedRoute) { }

    ngOnInit() {
        this.activeRouter.queryParams.subscribe(params => {
            if (params && params.impersonate) {
                this.impersonateLogin(params.impersonate);
            }
        });

        this.store.select(fromStore.authenticationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (state.status && !this.isAuthenticated) {
                this.isAuthenticated = state.status;
                this.router.navigate([`/home`]);
            }
        });

        this.getSelfRegistrationStatus();
        this.formLogin = this.fb.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]],
            login: ['username'],
            otp: ['']
        });

        if(this.systemSettings.isGeoTagEnabled){
          this.getLocation();
        }
    }
    getLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            },
            (error) => {
                console.error('Error getting location', error);
            }
        );
    }

    save(formLogin) {
        this.isSubmitted = true;
        let grantType = formLogin.value.otp ? LoginType.otpGrantType : LoginType.passwordGrantType;
        if (formLogin.valid) {
            let rawLogin = this.formLogin.getRawValue();
            this.authenticationService.login(rawLogin.username, rawLogin.password, rawLogin.login.toString(), grantType, this.referenceID,
                formLogin.value.otp).subscribe({
                    next: (result) => {
                        this.onLoginSucceeded(result);
                    },
                    error: (error) => {
                        this.messageService.showError(this.translate.instant(error.error.error));
                    }
                });
        }
    }

    useOTPasPassword(event) {
        if (this.formLogin.value.username === '') {
            this.formLogin.get('otp').setValue(false);
            this.messageService.showWarning(this.translate.instant(this.validationError));
        } else if (event.target.checked) {
            this.passwordLable = 'ParticipantLogin.OTP';
            this.passwordErrorMessage = 'ParticipantLogin.OTPRequired';
            this.formLogin.get('password').setValue("");
            this.sendOTP();
        }
        else {
            this.isOTPEnabled = false;
            this.passwordLable = 'ParticipantLogin.Password';
            this.passwordErrorMessage = 'ParticipantLogin.PasswordRequired';
            this.formLogin.controls['username'].enable();
        }

    }

    sendOTP() {
        let rawLoginDetails = this.formLogin.getRawValue();
        this.isOTPEnabled = false;
        this.authenticationService.getUserAccount(rawLoginDetails.username, rawLoginDetails.login, 'Login').subscribe(response => {

            this.authenticationService.sendOTP(RequestType.CS_Login, response.id).subscribe(response => {
                this.referenceID = response.referenceID;
                this.expiryTime = response.expiryTime;
                this.isResendOTP = true;
                this.calculateDateDifference(new Date(), new Date(response.expiryTime))
                this.isOTPEnabled = true;
                this.isPasswordEnabled = false;
                this.formLogin.controls['username'].disable();
                this.messageService.showSuccess(this.translate.instant('ParticipantLogin.OTPSentSuccessfully'));
            });
        });
    }

    changeLable() {
        if(this.formLogin.value.login === LoginType.mobile){
            this.formLogin.controls['username'].setValidators([Validators.pattern("[0-9]+$")]);
            this.formLogin.controls['username'].updateValueAndValidity();
            this.isMobileNumber = true;
            this.label = 'ParticipantLogin.MobileNo';
            this.validationError = 'ParticipantLogin.MobileNoRequired';
            this.validationMobileNumberError = 'ParticipantLogin.EnterMobileNumber';
        }
        else if(this.formLogin.value.login === LoginType.email){
            this.formLogin.controls['username'].setValidators([Validators.required]);
            this.formLogin.controls['username'].updateValueAndValidity();
            this.isMobileNumber = false;
            this.label = 'ParticipantLogin.EmailId';
            this.validationError = 'ParticipantLogin.EmailIdRequired';
        }
        else{
            this.formLogin.controls['username'].setValidators([Validators.required]);
            this.formLogin.controls['username'].updateValueAndValidity();
            this.isMobileNumber = false;
            this.label = 'ParticipantLogin.Username';
            this.validationError = 'ParticipantLogin.UsernameRequired';
        }

        this.formLogin.get('otp').setValue(false);
        this.passwordLable = 'ParticipantLogin.Password';
        this.passwordErrorMessage = 'ParticipantLogin.PasswordRequired';
        this.isResendOTP = false;
        this.formLogin.controls['username'].enable();
    }

    calculateDateDifference(startDate: Date, endDate: Date) {
        let startDateUtc = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds());
        let endDateUtc = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds());
        const expiryTime: number = ((endDateUtc - startDateUtc) / 1000)
        this.expiryMinutes = Math.floor(expiryTime / 60);
        this.expirySeconds = (expiryTime - this.expiryMinutes * 60);
    }

    onLoginSucceeded(result) {
        if (result.isAdmin) {
            window.location.href = environment.adminUrl + '/home';
        }
        else {

        }

        var data = {
            'userAgent': window.navigator.userAgent,
            'browserName': this.getBrowserName(),
            'browserVersion': '',
            'ipAddress': '',
            'loginDateTime': new Date().toLocaleString("en-US", { timeZone: this.systemSettings.ianaTimeZone }),
            'loginType': this.formLogin.value.login.toString(),
            'loginMethod': this.isOTPEnabled === true ? LoginType.otp : LoginType.password,
            'latitude': this.latitude,
            'longitude': this.longitude
        }
        this.PreloginService.saveUserLoginActivityResource(data).subscribe(response => {
            this.router.navigate([`/home`]);
        });
    }

    onImpersonateLoginSucceeded(){
        var data = {
            'userAgent': window.navigator.userAgent,
            'browserName': this.getBrowserName(),
            'browserVersion': '',
            'ipAddress': '',
            'loginDateTime': new Date().toLocaleString("en-US", { timeZone: this.systemSettings.ianaTimeZone }),
            'loginType': LoginType.username,
            'loginMethod': LoginType.password,
            'latitude': this.latitude,
            'longitude': this.longitude
        }
        this.PreloginService.saveUserLoginActivityResource(data).subscribe(response => {
            this.router.navigate([`/home`]);
        });
    }

    getBrowserName(): any {

        var userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("Edg") > -1) {
            return "edge";
        } else if (userAgent.indexOf("Chrome") > -1) {
            return "chrome";
        } else if (userAgent.indexOf("Safari") > -1) {
            return "safari";
        } else if (userAgent.indexOf("Firefox") > -1) {
            return "firefox";
        } else if (userAgent.indexOf("Trident") > -1 || userAgent.indexOf("MSIE") > -1) {
            return "ie";
        } else {
            return "unknown";
        }
    }

    getSelfRegistrationStatus() {
        this.PreloginService.getSelfRegistrationStatus().subscribe(response => {
            localStorage.setItem('selfregistrationStatus', JSON.stringify(response));
            this.isSelfRegistrationEnabled = response.isEnabled || response?.isEmeaEnabled;
            this.isSelfRegistrationRequired = response.isEnabled ? '/account/ActivateSelfRegistration' : '/account/Activate';
        });
    }

    show: boolean = false;
    password() {
        this.show = !this.show;
    }

    impersonateLogin(token: string) {
        let decodeToken = decodeURIComponent(token.split(' ').join('+'));
        this.authenticationService.impersonateLogin(decodeToken).subscribe(result => {
            this.onImpersonateLoginSucceeded();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
};
