import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowReferenceService, ICustomWindow, LoadingService } from '@core/shared';
import { PreloginService } from '@core/prelogin/services/prelogin.service';
import { filter, startWith, Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OrganisationSearchAddComponent } from './organisation-search-add/organisation.search.add.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountRegisterService } from './services/account-register.services';
import { ToastrService } from 'ngx-toastr';
import { UrlBuilder } from '@app/core/core-api-variables';
import { AccountRegisterConfirmationModalComponent } from './confirmation-modal/confirmation.modal.component';
import { browserRefresh } from '@app/app.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { HttpImageService } from '@core/shared/services/http-img.service';
import { EMEAProfanityWords } from '@app/core/shared/constants/emea-profanity-words';


let windowObject = window as any;
@Component({
  selector: 'app-account-register',
  templateUrl: './account-register.component.html',
  styleUrls: ['./account-register.component.scss']
})
export class AccountRegisterComponent implements OnInit, OnDestroy {
  private _window: ICustomWindow;
  lstcomments: any;
  contentPageId: string;
  contentPageDetails: any;
  public faInfoCircle = faInfoCircle;
  showLoginPageLink: boolean = false;
  selfRegistrationStatus: any;
  selectedCulture: string;
  selectedCountryCode: any = {};
  phoneCodeList: any = [{ name: '+91', value: '91' }];
  phoneCodes: any = [{ name: '+91', code: '+91', 'country': 'India', 'regexMobileNumber': /^[0-9]{10}$/ },
  { name: '+971', code: '+971', 'country': 'United Arab Emirates - UAE', 'regexMobileNumber': /^[0-9]{9}$/ },
  { name: '+33', code: '+33', 'country': 'France', 'regexMobileNumber': /^[0-9]{9}$/ },
  { name: '+234', code: '+234', 'country': 'Nigeria', 'regexMobileNumber': /^[0-9]{10}$/ }];
  postalCodeList: any = [
    { name: 'India', value: 'IND', regexPostalCode: /^[0-9]{6}$/ },
    { name: 'US', value: 'USA', regexPostalCode: /^[0-9]{5}$/ },
    { name: 'Australia', value: 'Australia', regexPostalCode: /^[0-9]{4}$/ },
    { name: 'UK', value: 'UK', regexPostalCode: /[A-Z]{1,2}[0-9]{1,2}[A-Z]?\s?[0-9][A-Z]{2}/i },
  ];
  badWordsList: any = [];
  countryList: any = [];
  jobRolesList: any = [];
  showCountryCodeList: boolean = false;
  organisationResult: any = {};
  languageChangeSubscription: Subscription;
  navigationStartSubscription: Subscription;
  phoneNumberRegex: any = '/^[0-9]{fff}$/';
  browserRefresh: boolean;
  public profileForm: FormGroup;
  public isSubmitClicked: boolean = false;
  constructor(public preloginService: PreloginService, public windowRef: WindowReferenceService, public loadingService: LoadingService,
    private modal: NgbModal, private accountRegisterService: AccountRegisterService, public toaster: ToastrService,
    public translate: TranslateService, private router: Router, private imgService: HttpImageService) {
    if (localStorage.getItem('selfregistrationStatus'))
      this.selfRegistrationStatus = JSON.parse(localStorage.getItem('selfregistrationStatus'));
    if (this.selfRegistrationStatus?.isEmeaEnabled) {
      this.selectedCountryCode = { 'name': '+91', 'value': '91' };
      this.badWordsList = EMEAProfanityWords?.profanitywords;
      this.initiateForm();
    }
    else if (this.selfRegistrationStatus?.isEnabled) {
      this._window = windowRef.nativeWindow;
    }
  }

  initiateForm() {
    this.profileForm = new FormGroup({
      userName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(/^\S*$/), this.validateForBadWords.bind(this)]),
      firstName: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      emailAddress: new FormControl('', [Validators.required, Validators.maxLength(70), Validators.pattern('^([a-zA-Z0-9_\\-\\.+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$')]),
      jobTitle: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      jobRole: new FormControl('', [Validators.required]),
      profileCountry: new FormControl('', [Validators.required]),
      country: new FormControl({ value: '', disabled: false }, [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]), // mobile number based on country code pending
      name: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(200)]),
      address1: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(50)]),
      address2: new FormControl({ value: '', disabled: false }, [Validators.maxLength(50), Validators.minLength(0)]),
      town: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(50)]),
      postCode: new FormControl({ value: '', disabled: false }, [Validators.minLength(1), Validators.maxLength(6), Validators.pattern(/^\d{6}$/)]),
      confirmCheckBox: new FormControl(false)
    });
  }
  validateForBadWords(control: AbstractControl): { [key: string]: any } | null {
    let valid: boolean = false;
    if (control.value) {
      const value = control.value.toLowerCase();
      valid = this.badWordsList.find(x => value.indexOf(x.toLowerCase()) != -1);
    }
    return valid ? { 'userNameInvalid': true } : null;
  }

  updatePostCodePatternValidation(value) {
    const obj = this.postalCodeList.find(x => x.value == value);
    if (obj?.regexPostalCode) {
      this.profileForm.controls.postCode.setValidators([Validators.pattern(new RegExp(obj?.regexPostalCode)), Validators.required]);
      this.profileForm.controls.postCode.updateValueAndValidity();
    }
  }
  ngOnInit(): void {
    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.navigationStartSubscription = this.router.events.pipe(
        filter((e) => e instanceof RouterEvent),
        startWith('Initial call')
      ).subscribe((res: NavigationEnd) => {
        this.browserRefresh = browserRefresh;
        this.getConfig();
      });
      if (!this.browserRefresh) {
        window.location.reload();
        this.getContentPage(this.contentPageId);
      }
      else
        this.browserRefresh = false;
    });
    if (this.selfRegistrationStatus?.isEmeaEnabled) {
      this.getCountries();
      this.getJobRoles();
      this.getPhoneCodes();
      this.profileForm.controls.country.valueChanges.subscribe(value => {
        this.updatePostCodePatternValidation(value)
      });
      // this.profileForm.controls.profileCountry.valueChanges.subscribe(value => {
      //   this.updateMobileNumberLength(value)
      // });
    }
    else if (this.selfRegistrationStatus?.isEnabled) {
      windowObject.parent.preRegistrationCallBack = function (userDetails): Promise<any> {
        let success = {
          "success": true,
          "errorCode": "",
          "responseMessage": "",
          "customAttributes": {
            "CreateOrganisation": "{\"Name\":\"{{Your Organisation Name}}\",\"OrganisationCode\":\"{{Your Organisation Code}}\",\"Online\":true,\"HierarchyGroupId\":\"21058A49-6BF2-4163-8847-AC0600724F64\",\"Address1\":\"Line1\",\"Address2\":\"Line2\",\"Address3\":\"Line3\",\"Town\":\"Agra\",\"County\":\"IND\",\"PostCode\":\"451231\",\"CountryIsoCode\":\"IND\",\"Phone\":\"1543215432\",\"Email\":\"<Email Address>\",\"Attributes\":[{\"Name\":\"Attr1\",\"ValueAsString\":\"ValueAsString1\",\"DataType\":\"Text\",\"SortOrder\":\"1\"},{\"Name\":\"Attr2\",\"ValueAsString\":\"ValueAsString2\",\"DataType\":\"Text\",\"SortOrder\":\"2\"}],\"HierarchyGroupIds\":[\"567a3749-3cdd-415c-b9c8-ac8b0060fed5\"]}"
          }
        };
        return Promise.resolve(success);
      };

      windowObject.parent.addressCallback = async (val, data) => {

        let details = {
          "localeCode": this.selectedCulture,
          "code": data
        };
        switch (val) {
          case "COUNTRY":
            let countryPromise = await this.preloginService.getCountries(details);
            return countryPromise.country;
          case "STATE":
            let statesPromise = await this.preloginService.getStates(details);
            return statesPromise.state;
          case "CITY":
            let cityPromise = await this.preloginService.getCities(details);
            return cityPromise.city;
          default:
            return Promise.reject();
        }
      }

      windowObject.parent.postRegistrationCallBack = (userDetails, message) => {
        this.showLoginPageLink = true;
      };
    }
  }
  updateMobileNumberLength(item) {
    const obj = this.phoneCodes.find(x => x.name == item.name);
    if (obj?.regexMobileNumber) {
      this.profileForm.controls.mobileNumber.setValidators([Validators.required, Validators.pattern(new RegExp(obj?.regexMobileNumber))]);
      this.profileForm.controls.mobileNumber.updateValueAndValidity();
    }
  }
  getContentPage(contentPageId) {
    this.selectedCulture = localStorage.getItem('culture');
    this.loadingService.doLoading(this.preloginService.getRegisterContentPage(contentPageId, this.selectedCulture), this)
      .subscribe(response => {
        this.contentPageDetails = this.imgService.parseHtml(response);
        setTimeout(() => {
          this.fetchBlobImages();
        });
      });
  }

  fetchBlobImages() {
    let images = document.querySelectorAll('img[data-src]');
    for (let image of Array.from(images)) {
      let dataSrc = image.getAttribute('data-src');
      this.imgService.getImage(dataSrc).subscribe(val => {
        let type = val.type;
        let reader = new FileReader();
        reader.readAsDataURL(val);
        reader.onloadend = function () {
          let baseImage = <string>reader.result;
          image.setAttribute('src', `data:${type};base64,` + baseImage.split(',')[1]);
        }
      });
    }
  }

  getPhoneCodes() {
    this.accountRegisterService.getPhoneCodes().subscribe(res => {
      this.phoneCodeList = res;
      if (res && res[0]) {
        this.selectedCountryCode = res[0];
        this.updateMobileNumberLength(res[0]);
      }
      else {
        this.selectedCountryCode = {};
      }
    });
  }
  getCountries() {
    this.accountRegisterService.getCountries().subscribe(res => {
      this.countryList = res?.country ? res.country : [];
    });
  }
  getJobRoles() {
    this.accountRegisterService.getJobRoles().subscribe(res => {
      this.jobRolesList = res?.listViews ? res.listViews : [];
    });
  }
  checkForUniqueUserName() {
    this.accountRegisterService.isDuplicateUserNameCheck({ username: this.profileForm.controls.userName.value }).subscribe(res => {
      if (res?.userExits) {
        this.toaster.error(UrlBuilder(this.translate.instant('AccountRegister.USERNAME_EXISTS'), { username: this.profileForm.controls.userName.value }));
      }
    });
  }
  openSearchAddComponent() {
    const modalInstance = this.modal.open(OrganisationSearchAddComponent, { centered: true });
    modalInstance.componentInstance.countryList = this.countryList;
    modalInstance.result.then((res) => {
      if (res?.name) {
        this.organisationResult = res;
        this.profileForm.patchValue(res);
        if (!res?.id && res?.country) {
          this.updatePostCodePatternValidation(res?.country);
          this.profileForm.controls.name.enable();
          this.profileForm.controls.country.enable();
          this.profileForm.controls.address1.enable();
          this.profileForm.controls.address2.enable();
          this.profileForm.controls.town.enable();
          this.profileForm.controls.postCode.enable();
        }
        else {
          this.profileForm.controls.name.disable();
          this.profileForm.controls.country.disable();
          this.profileForm.controls.address1.disable();
          this.profileForm.controls.address2.disable();
          this.profileForm.controls.town.disable();
          this.profileForm.controls.postCode.disable();
        }
      }
    });
  }
  cancel() {
    this.initiateForm();
  }
  submit() {
    this.isSubmitClicked = true;
    const formValues = this.profileForm.getRawValue();
    if (this.profileForm.valid) {
      if (formValues?.confirmCheckBox) {
        const payload = {
          "Addresses": [],
          "Attributes": [
            {
              "Name": "Job Role",
              "Value": formValues?.jobRole
            },
            {
              "Name": "TermsAcceptance",
              "Value": true
            },
            {
              "Name": this.organisationResult?.id ? "Organisation" : "CreateOrganisation",
              "Value": this.organisationResult?.id ? this.organisationResult?.id : JSON.stringify(
                {
                  Name: formValues?.name,
                  Address1: formValues?.address1,
                  Address2: formValues?.address2,
                  Town: formValues?.town,
                  PostCode: formValues?.postCode,
                  OrganisationCode: '',
                  Online: true,
                  HierarchyGroupId: '00000000-0000-0000-0000-000000000000',
                  HierarchyGroupIds: ['00000000-0000-0000-0000-000000000000'], // not mandatory
                  CountryIsoCode: formValues?.country,
                  // Phone: this.profileForm.controls.mobileNumber.value, // not mandatory so removing from payload
                  // Email: this.profileForm.controls.emailAddress.value, // not mandatory so removing from payload
                  // County: this.profileForm.controls.country.value, // not mandatory so removing from payload
                  Attributes: []
                }
              )
            }
          ],
          "Country": formValues?.profileCountry,
          "Emails": [
            {
              "Address": formValues?.emailAddress,
              "Label": "Email"
            }
          ],
          "ExternalId": formValues?.userName,
          "GivenName": formValues?.firstName,
          "HireDate": null,
          "Locale": null,
          "MiddleName": '',
          "Nickname": null,
          "Phones": [
            {
              "CountryPhoneCode": this.selectedCountryCode?.value,
              "Label": "Mobile Number",
              "PhoneExt": null,
              "PhoneNumber": formValues?.mobileNumber
            }
          ],
          "Pronouns": {
            "Objective": null,
            "Subjective": null
          },
          "Suffix": null,
          "Surname": formValues?.lastName,
          "TerminationDate": null,
          "Title": null
        }
        this.loadingService.doLoading(this.accountRegisterService.accountRegistration(payload), this)
          .subscribe(res => {
            if (res?.errorCode || res?.errorMessage) {
              this.toaster.error(this.translate.instant('AccountRegister.' + res?.errorCode) || res?.errorMessage);
            }
            else {
              const modalRef = this.modal.open(AccountRegisterConfirmationModalComponent, { backdrop: 'static', centered: true });
              modalRef.componentInstance.message = this.translate.instant('AccountRegister.RegisterSuccessMsg');
            }
          }, (err) => {
          });
      }
    }
  }

  getConfig() {
    this.selectedCulture = localStorage.getItem('culture');
    this.preloginService.selfRegistrationConfig().subscribe(data => {
      if (this.selfRegistrationStatus?.isEnabled)
        this.renderForm(data);
      this.contentPageId = data.contentUserRegisterFormId;
      if (this.contentPageId)
        this.getContentPage(this.contentPageId);
    })
  }
  renderForm(configs) {
    this.preloginService.selfRegistration(configs, this.selectedCulture).subscribe(data => {
      this._window.__selfRegistrationAppData__ = data;
      this._window.loadSelfRegistrationInDom('selfRegistrationRoot');
    });
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }

  isShownDivMaterial: boolean = false; // hidden by default
  toggleShowsMaterial() {
    this.isShownDivMaterial = !this.isShownDivMaterial;
  }
}
