import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UrlBuilder } from '@app/core/core-api-variables';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cs-choose-file',
    templateUrl: './choose.file.component.html',
    styles: ['./choose.file.component.scss']
})

export class ChooseFileComponent implements OnInit {
    @Output() onImportComplete = new EventEmitter();
    public fileName: string = '';
    public errMsg: string = '';
    @Input() supportedFiletypes: string = '.png, .jpg, .jpeg';
    @Input() maxFileSize: number = 1024;
    @Input() fileMeasuringUnit: string = 'kb';
    constructor(public translate: TranslateService) { }

    ngOnInit() {

    }

    onChangeFile(event: any) {
        if (event?.target?.files?.length) {
            console.log(event.target.files[0])
            const file = event.target.files[0];
            this.fileName = file?.name;
            const fileType = this.fileName.split('.');
            if (this.supportedFiletypes?.toLowerCase()?.indexOf(fileType[fileType?.length - 1]?.toLowerCase()) == -1) {
                this.errMsg = UrlBuilder(this.translate.instant('AccountRegister.FileUpload_Supported_File_Formats'), { SupportedFiles: this.supportedFiletypes });
            }
            else if (Math.ceil((file?.size / this.maxFileSize)) > 1) {
                this.errMsg = UrlBuilder(this.translate.instant('AccountRegister.FileUpload_EXCEEDS_MAX_VALUE'), { MaxValue: this.maxFileSize + ' ' + 'kb' });
            }
            else {
                this.onImportComplete.emit(file);
            }
        }
        else {
            this.errMsg = this.translate.instant('AccountRegister.FileUpload_REQUIRED_ATTRIBUTE');
        }
    }
}