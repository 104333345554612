<ng-container>
    <form [formGroup]="formActivation" class="common-prelogin-form round-card shadowed bg-color2"
        (ngSubmit)="activate(formActivation)">
        <form-input controlName="type" inputType="hidden"></form-input>

        <div class="margin-bottom-20 text-center">
            <body-text class="medium-size-plus-4 font-bold">{{ 'ParticipantActivation.FillInYourDetails' | translate }}
            </body-text>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.FirstName' | translate }}<span
                    class="mandatory">*</span>
            </body-text>
            <form-input controlName="firstName" theme="theme2"
                placeHolder="{{'ParticipantActivation.FirstName' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.firstName.valid && (formActivation.controls.firstName.touched || submitted)">
                <span [hidden]="!formActivation.controls['firstName'].hasError('required')"
                    translate="ParticipantActivation.FirstNameRequired"></span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.LastName' | translate }}<span
                    class="mandatory">*</span>
            </body-text>
            <form-input controlName="lastName" theme="theme2"
                placeHolder="{{'ParticipantActivation.LastName' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.lastName.valid && (formActivation.controls.lastName.touched || submitted)">
                <span [hidden]="!formActivation.controls['lastName'].hasError('required')"
                    translate="ParticipantActivation.LastNameRequired"></span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Email' | translate }}<span
                    class="mandatory">*</span></body-text>
            <form-input controlName="email" theme="theme2" inputType="email"
                placeHolder="{{'ParticipantActivation.Email' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.email.valid && (formActivation.controls.email.touched || submitted)">
                <span [hidden]="!formActivation.controls['email'].hasError('required')"
                    translate="ParticipantActivation.EmailInvalid"></span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.MobilePhoneNumber' | translate }}
            </body-text>
            <form-input controlName="mobileNumber" theme="theme2"
                placeHolder="{{'ParticipantActivation.MobilePhoneNumber' | translate}}">
            </form-input>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.JobRole' | translate }}
            </body-text>
            <form-input controlName="jobRole" theme="theme2"
                placeHolder="{{'ParticipantActivation.JobRole' | translate}}">
            </form-input>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.Password' | translate }}<span
                    class="mandatory">*</span>
            </body-text>
            <form-input controlName="password" theme="theme2" inputType="password"
                placeHolder="{{'ParticipantActivation.Password' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.password.valid && (formActivation.controls.password.touched || submitted)">
                <span [hidden]="formActivation.controls.password.valid">
                    {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.PasswordDoesNotMeetMinimumRequirement' |
                    translate }}
                    <br />
                    {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.Must' | translate }}
                    <ul>
                        <li>
                            {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.BeAtleast10Characters' |
                            translate }}
                        </li>
                        <li>
                            {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneNumber' |
                            translate }}
                        </li>
                        <li>
                            {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneLowercaseLetter'
                            | translate }}
                        </li>
                        <li>
                            {{ 'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneUppercaseLetter'
                            | translate }}
                        </li>
                        <li>
                            {{
                            'ParticipantActivation.PasswordDoestNotMeetRequirements.IncludeAtLeastOneSpecialCharacter' |
                            translate }}
                        </li>
                    </ul>
                </span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.ConfirmPassword' | translate
                }}<span class="mandatory">*</span>
            </body-text>
            <form-input controlName="confirmPassword" theme="theme2" inputType="password"
                placeHolder="{{'ParticipantActivation.ConfirmPassword' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.confirmPassword.valid && (formActivation.controls.confirmPassword.touched || submitted)">
                <span [hidden]="formActivation.controls.confirmPassword.valid"
                    translate="ParticipantActivation.ConfirmPasswordMustMatch"></span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.SecurityQuestion' | translate
                }}<span class="mandatory">*</span>
            </body-text>
            <form-button-select [value]="formActivation.get('securityQuestion').value" theme="theme2"
                [options]="securityQuestion" placeHolder="{{ 'Shared.SelectLists.PleaseSelect' | translate}}"
                optionsLabelKey="label" optionstrackbyKey="value" [translateLabel]="true"
                (onChange)="securityQuestionChange($event)">
            </form-button-select>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.securityQuestion.valid && (formActivation.controls.securityQuestion.touched || submitted)">
                <span [hidden]="!formActivation.controls['securityQuestion'].hasError('required')">*
                    Security question is required.</span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <body-text class="medium-size block padding-h-5">{{ 'ParticipantActivation.SecurityAnswer' | translate
                }}<span class="mandatory">*</span>
            </body-text>
            <form-input controlName="securityAnswer" theme="theme2"
                placeHolder="{{'ParticipantActivation.SecurityAnswer' | translate}}">
            </form-input>
            <div class="help-block text-danger"
                *ngIf="!formActivation.controls.securityAnswer.valid && (formActivation.controls.securityAnswer.touched || submitted)">
                <span [hidden]="!formActivation.controls['securityAnswer'].hasError('required')"
                    translate="ParticipantActivation.SecurityAnswerRequired"></span>
            </div>
        </div>

        <div class="margin-bottom-10">
            <form-checkbox controlName="termsAccepted" [value]="formActivation.get('termsAccepted').value"
                [style.display]="'inline-block'">
            </form-checkbox>
            <a (click)="termsAndConditions(terms)">{{ 'ParticipantActivation.TermsAndConditions' |
                translate }}</a>
            <div class="help-block text-danger" *ngIf="!formActivation.controls.termsAccepted.valid && submitted">
                <span [hidden]="formActivation.controls.termsAccepted.valid"
                    translate="ParticipantActivation.YouMustAcceptTerms"></span>
            </div>
           <div class="margin-top-30">
              <form-checkbox controlName="preferenceAccepted" [style.display]="'inline-block'"
                             [value]="formActivation.get('preferenceAccepted').value"></form-checkbox>
              <a>{{ 'ParticipantActivation.Notificationpreference' |translate }}</a>
            </div>
            <div class="margin-top-30">
                <form-button text="{{ 'ParticipantActivation.Submit' | translate }}" id="submit" theme="theme5" size="stretch">
                </form-button>
            </div>
        </div>

    </form>
</ng-container>
<ng-template #terms let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'ParticipantActivation.TermsAndConditions' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div innerHtml="{{ 'ParticipantActivation.Terms' | translate }}"></div>
    </div>
</ng-template>
