import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { SystemSettings } from "@core/models";
import {
  faGlobeAmericas,
  faUser,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Subject, Subscription } from "rxjs";
import { filter, startWith, takeUntil } from "rxjs/operators";

import { browserRefresh } from "@app/app.component";
import { AuthenticationService } from "@core/startup";
import * as fromStore from "@core/store";
import { Authentication } from "@core/models/authentication.model";
import { SelfRegistrationService } from "@app/core/shared/services/selfRegistration.service";
import { MessageCentreService } from "../../services/message-centre.service";
import { environment } from "@env/environment";
import * as actions from "@core/store/actions";
declare let gtag: Function;

@Component({
  selector: "cs-logo-bar-component",
  templateUrl: "./logo-bar.component.html",
  styleUrls: ["./logo-bar.component.scss"],
})
export class LogoBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isAuthenticated: boolean;
  @Input() isPreLogin: boolean;
  @Input() theme: string;
  @Input() isFullView: boolean;

  isImpersonate: boolean;
  browserRefresh: boolean;
  isShown: boolean = false; // hidden by default
  public currentUser: any;
  public selectedCulture = "";
  public faGlobeAmericas = faGlobeAmericas;
  public faUser = faUser;
  public faBell = faBell;
  private onDestroy$ = new Subject<void>();
  public isSelfRegistrationEnabled: boolean = false;
  navigationStartSubscription: Subscription;
  public userId: any;
  public notificationsCount: any;
  menuAccess: any;
  displayLanguage: any;
  constructor(
    public systemSettings: SystemSettings,
    public authService: AuthenticationService,
    public translate: TranslateService,
    public router: Router,
    private store: Store<fromStore.PlatformState>,
    public selfRegistrationService: SelfRegistrationService,
    public messageCentreService: MessageCentreService
  ) {
    let culture = localStorage.getItem("culture");
    if (culture) {
      this.selectedCulture = culture;
      translate.setDefaultLang(culture);
    } else {
      this.selectedCulture = systemSettings.defaultPlatformLanguage;
      translate.setDefaultLang(systemSettings.defaultPlatformLanguage);
      localStorage.setItem("culture", systemSettings.defaultPlatformLanguage);
    }

    this.displayLanguage = this.systemSettings.platformLanguages.find(
      (x) => x.languageCulture == this.selectedCulture
    ).languageName;

    this.store
      .select(fromStore.authenticationState)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => {
        this.currentUser = state.user;
        this.userId = state.user?.Id;
        this.isImpersonate = state.isImpersonate;
      });

    this.store
      .select(fromStore.globalMenuState)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => {
        this.menuAccess = state.menuAccess;
      });
  }

  ngOnInit() {
    this.navigationStartSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof RouterEvent),
        startWith("Initial call")
      )
      .subscribe((res: NavigationEnd) => {
        this.browserRefresh = browserRefresh;
      });
  }

  getNotificationCount() {
    this.messageCentreService.getAllUnReadNotifications().subscribe((data) => {
      this.notificationsCount = data.count;
    });
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change.isAuthenticated &&
      change.isAuthenticated.previousValue !=
        change.isAuthenticated.currentValue
    ) {
      if (change.isAuthenticated.currentValue && !this.currentUser) {
        this.getUser();
        this.getGlobalMenuAccess();
        this.getNotificationCount();
      }
    }
  }

  changeLanguage(language) {
    localStorage.setItem("culture", language);
    this.translate.use(language);
    this.selectedCulture = language;
    this.displayLanguage = this.systemSettings.platformLanguages.find(
      (x) => x.languageCulture == this.selectedCulture
    ).languageName;
  }

  getUser() {
    this.authService.getCurrentUser().subscribe((response) => {
      let authentication = new Authentication(
        response.id,
        response.username,
        response.firstName,
        response.lastName,
        response.culture,
        response.primaryOrganisation.id,
        response.primaryOrganisation.organisationCode,
        response.primaryOrganisation.name,
        response.tier,
        response.userCookiePolicyAcceptedDetails
      );
      this.currentUser = authentication;
      this.store.dispatch({
        type: actions.UPDATE_USER,
        payload: authentication,
      });

      if (
        response.userCookiePolicyAcceptedDetails &&
        response.userCookiePolicyAcceptedDetails.tracking
      ) {
        gtag("config", environment.gaKey, { user_id: response.id });
      }

      if (!this.browserRefresh) {
        localStorage.setItem("culture", response.culture);
        this.translate.use(response.culture);
        this.selectedCulture = response.culture;
        this.displayLanguage = this.systemSettings.platformLanguages.find(
          (x) => x.languageCulture == this.selectedCulture
        ).languageName;
      }
    });
  }

  getGlobalMenuAccess() {
    this.authService.getGlobalMenuAccess().subscribe((response) => {
      this.store.dispatch({
        type: actions.CHANGE_GLOBAL_MENU_ACCESS,
        payload: response,
      });
    });
  }

  logoClicked() {
    if (this.isAuthenticated) {
      this.router.navigate(["home"]);
    } else {
      this.router.navigate([""]);
    }
  }

  logout() {
    this.currentUser = null;
    this.authService.logout();
  }

  editProfile() {
    this.selfRegistrationService.selfRegistrationStatus().subscribe((data) => {
      this.isSelfRegistrationEnabled = data.isEnabled;
      if (!this.isSelfRegistrationEnabled) {
        this.router.navigate([`/profile`]);
      } else {
        this.router.navigate([`/editprofile/selfregistration`]);
      }
    });
  }

  changePassword() {
    this.router.navigate([`/changepassword`]);
  }
  changePreference() {
    this.router.navigate([`/notificationsubscription`]);
  }
  changeSecurityQuestion() {
    this.router.navigate([`/securityquestion/`, this.userId]);
  }

  messageCentre() {
    this.router.navigate(["/messagecentre"]);
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }
  changeTheme(theme) {
    this.store.dispatch({ type: actions.UPDATE_THEME, payload: theme });
  }

  ngOnDestroy() {
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}
