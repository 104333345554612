<ng-container>
  <form [formGroup]="formActivationCheck" class="common-prelogin-form round-card shadowed bg-color2"
    (ngSubmit)="validate(formActivationCheck)" #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this)">
    <div class="margin-bottom-20">
      <body-text class="medium-size-plus-4">{{'ParticipantActivation.ActivateYourAccount' | translate}}
      </body-text>
    </div>
    <div class="margin-bottom-20">
      <ng-container *ngFor="let login_Type of loginType">
        <input type="radio" formControlName="login" value="{{login_Type.value}}" (change)="changeLable()">
        {{login_Type.name}}
      </ng-container>
    </div>
    <div class="margin-bottom-10">
      <form-input controlName="username" placeHolder="{{label| translate}}"></form-input>
      <div class="help-block text-danger"
        *ngIf="!formActivationCheck.controls.username.valid && isSubmitted">
        <span [hidden]="!formActivationCheck.controls['username'].hasError('required')"
          translate="{{validationError | translate}}"></span>
      </div>
    </div>

    <div class="margin-bottom-10" *ngIf="systemSettings.otpBasedActivation" >
      <input type="checkbox" formControlName="otp" value="OTP" (change)="useOTPasPassword($event)">
      {{'ParticipantActivation.UseOTPAsActivationCode' | translate}}
    </div>
    <div class="otp-display" *ngIf="this.isOTPEnabled && expiryTime && this.isResendOTP">
      <div class="otp-display-message">{{'ParticipantActivation.OtpValidityMessage'| translate}} </div>
      <cs-timer [endDate]="expiryTime" [showOnlyMinutes]="'true'"></cs-timer>
    </div>
    <div class="margin-bottom-20" *ngIf="this.isOTPEnabled && expiryTime && this.isResendOTP">
      <a class="otp-link"  href="javascript:void(0);" (click)="sendOTP()" >{{'ParticipantLogin.RegenerateOTP' | translate}}</a>
    </div>
    <div class="margin-bottom-10">
    <!--   <form-input controlName="activationCode" placeHolder="{{passwordLable | translate}}">
      </form-input> -->
      <input   [type]="show ? 'text' : 'password'" class="form-control passwords" formControlName="activationCode" 
      placeholder="{{passwordLable | translate}}" />
      <span (click)="password()" class="{{show ? 'openEye' : 'closeEye'}}"></span>

      <div class="help-block text-danger"
        *ngIf="!formActivationCheck.controls.activationCode.valid && isSubmitted">
        <span [hidden]="!formActivationCheck.controls['activationCode'].hasError('required')"
          translate="{{passwordErrorMessage | translate}}"></span>
      </div>
    </div>
    <div class="margin-bottom-20 margin-top-20">
      <form-button text="{{ 'ParticipantActivation.Submit' | translate }}" size="stretch" buttonType="submit">
      </form-button>
    </div>

    <div class="text-center">
      <a class="link-no-decor" [routerLink]="['/']">
        <body-text class="primary-color medium-size">{{'ParticipantActivation.ExistingUserLogin'| translate}}
        </body-text>
      </a>
    </div>

  </form>
</ng-container>