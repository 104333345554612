import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SystemSettings } from "@core/models";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

import { browserRefresh } from "@app/app.component";
import { MenuService, Menu } from "./menu.service";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { filter, pairwise, startWith } from "rxjs/operators";
import { environment } from "@env/environment";
import { SharedService } from "@app/core/shared";
import { CookieService } from "ngx-cookie-service";
import { CatalogueService } from "@core/shared/services/catalogue.service";
import { createCatalogueMap } from "./catalogueConfig";

@Component({
  selector: "menu-component",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() userId;

  isCollapsed = true;
  navbarCollapsed = true;
  public menu: Menu[];
  selectedCulture: string;
  defaultCulture: string;
  browserRefresh: boolean;
  isEnabledReport: boolean = false;
  languageChangeSubscription;
  navigationStartSubscription;

  routes: any = {
    couponClaim: "coupon",
    couponClaimHistory: "coupon",
    invoiceClaimSubmission: "invoice",
    invoiceClaimApproval: "invoice",
    invoiceClaimHistory: "invoice",
    contentManaged: "content",
    embeddedReportPage: "reports",
    gamePage: "engage",
    goalQuestPage: "goalquest",
    managerGoalQuestPage: "goalquest",
    hubPage: "hub",
    league: "contest",
    membershipTier: "tier",
    messagePage: "articles",
    myAwards: "awards",
    myPerformancePage: "performance",
    myThresholdAwards: "threshold",
    questionPage: "faq",
    quizAdminPage: "quizsurvey",
    quizAndQuestionnairePage: "quizsurvey",
    claimForm: "claimforms",
    communicationPage: "promotion",
  };

  cookieData: object;
  catalogueMap: any;

  constructor(
    private router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private systemSettings: SystemSettings,
    private sharedService: SharedService,
    private catalogueService: CatalogueService
  ) {
    this.getMenu();
    const cookieConsent = JSON.parse(
      localStorage.getItem("cookieConsent") || "{}"
    );

    if (cookieConsent.status === "preAccepted") {
      this.processCookieConsent();
    }
    this.catalogueMap = createCatalogueMap(catalogueService);
  }
  processCookieConsent(): void {
    this.sharedService.getCookies().subscribe((res: any) => {
      const expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 730);
      const cookieConsent = JSON.parse(
        localStorage.getItem("cookieConsent") || "{}"
      );

      if (!cookieConsent.version && !res.isUserAcceptedCookiePolicy) {
        this.cookieData = {
          contentPageId: res.contentPage?.id,
          version: res.contentPage?.version,
          ...cookieConsent.preferences,
        };

        localStorage.setItem(
          "cookieConsent",
          JSON.stringify({
            ...this.cookieData,
            status: "accepted",
            expireTime: expireDate.getTime(),
          })
        );
        this.sharedService.setCookies(this.cookieData).subscribe(() => {});
      } else if (
        res.contentPage &&
        res.contentPage.version !== cookieConsent.version &&
        !res.isUserAcceptedCookiePolicy
      ) {
        localStorage.removeItem("cookieConsent");
      } else if (!cookieConsent.version && res.isUserAcceptedCookiePolicy) {
        this.cookieData = {
          contentPageId: res.contentPage.id,
          version: res.acceptedCookiePolicyDetails.acceptedVersion,
          functional: res.acceptedCookiePolicyDetails.functional,
          tracking: res.acceptedCookiePolicyDetails.tracking,
          performance: res.acceptedCookiePolicyDetails.performance,
          personalization: res.acceptedCookiePolicyDetails.personalization,
        };

        localStorage.setItem(
          "cookieConsent",
          JSON.stringify({
            ...this.cookieData,
            status: "accepted",
            expireTime: expireDate.getTime(),
          })
        );
      } else if (res.isUserAcceptedCookiePolicy) {
        this.cookieData = {
          contentPageId: res.contentPage.id,
          version: res.acceptedCookiePolicyDetails.acceptedVersion,
          functional: res.acceptedCookiePolicyDetails.functional,
          tracking: res.acceptedCookiePolicyDetails.tracking,
          performance: res.acceptedCookiePolicyDetails.performance,
          personalization: res.acceptedCookiePolicyDetails.personalization,
        };

        localStorage.setItem(
          "cookieConsent",
          JSON.stringify({
            ...this.cookieData,
            status: "accepted",
            expireTime: expireDate.getTime(),
          })
        );
      }
    });
  }

  ngOnInit() {
    this.navigationStartSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof RouterEvent),
        pairwise(),
        filter((events) => events[0] !== events[1]),

        startWith("Initial call")
      )
      .subscribe((res) => {
        this.browserRefresh = browserRefresh;
      });

    this.languageChangeSubscription =
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        if (!this.browserRefresh) this.getMenu();
        else this.browserRefresh = false;
      });
  }

  getMenu() {
    this.selectedCulture = localStorage.getItem("culture");
    this.defaultCulture = this.systemSettings.defaultPlatformLanguage;
    this.menuService.getMenu().subscribe((response) => {
      this.menu = this.buildMenu(response);
    });
  }

  buildMenu(menus: Menu[]) {
    let tempMenus: Menu[] = [];
    menus.forEach((menu) => {
      if (!menu.displayNone) {
        let tempMenu: Menu;
        tempMenu = Object.assign({}, menu);
        tempMenu["isForTabView"] = false;
        if (
          menu.menuType === "expand" &&
          menu.attributesJson &&
          menu.children.length > 0
        ) {
          tempMenu["isForTabView"] = JSON.parse(menu.attributesJson)[
            "isForTabView"
          ];
          if (tempMenu["isForTabView"])
            tempMenu.url =
              menu.children.filter((x) => !x.displayNone).length > 0
                ? `multiview/${menu.id}/${
                    menu.children.filter((x) => !x.displayNone)[0].url
                  }`
                : "";
        } else {
          if (this.routes[menu.menuType])
            tempMenu.url = `${this.routes[menu.menuType]}/${menu.url}`;
        }
        tempMenu.children = this.buildMenu(menu.children);
        tempMenus.push(tempMenu);
      }
    });
    return tempMenus;
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  reportImpersonate() {
    if (this.userId) {
      this.menuService
        .getImpersonateToken(this.userId)
        .subscribe((response: any) => {
          if (response.token) {
            let url = `${environment.platformUrl}/Login/?impersonate=${response.token}`;
            window.open(url, "_blank");
          }
        });
    }
  }

  hasValueDeep(json, findValue) {
    const values = Object.values(json);
    let hasValue = values.includes(findValue);
    values.forEach((value) => {
      if (typeof value === "object") {
        hasValue = hasValue || this.hasValueDeep(value, findValue);
      }
    });
    return hasValue;
  }

  rewardCatalogue(menuType) {
    const catalogue = this.catalogueMap[menuType];
    if (!catalogue) {
      console.error(`Unknown catalogue type: ${menuType}`);
      return;
    }
    this.handleCatalogueRedirect(catalogue);
  }

  handleCatalogueRedirect(catalogue) {
    try {
      if (this.systemSettings.ssoCatelogueOtpBasedLogin) {
        this.router.navigate([catalogue.verificationPath]);
      } else {
        catalogue.redirectMethod();
      }
    } catch (error) {
      console.error("Error during catalogue redirection:", error);
    }
  }

  ngOnDestroy() {
    if (this.languageChangeSubscription)
      this.languageChangeSubscription.unsubscribe();
    if (this.navigationStartSubscription)
      this.navigationStartSubscription.unsubscribe();
  }
}
