<div class="prelogin-body">
  <div class="content-width">
    <div class="self-registration-page-section">
      <div class="row">
        <div class="mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <div class="self-registration-page" *ngIf="selfRegistrationStatus?.isEnabled">
                <div class="cms-card-body" *ngIf="contentPageDetails != undefined">
                  <div class="custom-toggle">
                    <div class="header">
                      <div class="header-title">{{'Help'}}</div>
                      <button (click)="toggleShowsMaterial()" class="btn btn-link accordion">{{!isShownDivMaterial ? '+'
                        :'-'}}</button>
                    </div>
                    <div class="opens bg" [class]="{'newClass':isShownDivMaterial}">
                      <div [innerHTML]="contentPageDetails | sanitizeHtml" *ngIf="contentPageDetails != undefined">
                      </div>
                    </div>
                  </div>
                </div>
                <div id="selfRegistrationRoot"></div>
              </div>
              <div class="self-registration-section" *ngIf="selfRegistrationStatus?.isEmeaEnabled"
                #dataLoading="appIsLoading" [appIsLoading]="loadingService.isLoading(this)">
                <div class="text-center pb-5 mb-3">
                  <p class="page-heading">{{'AccountRegister.PageTitle' | translate}}</p>
                  <!-- <span class="page-info-section">{{'AccountRegister.PageInfo' | translate}}</span> -->
                  <div [innerHTML]="contentPageDetails | sanitizeHtml" *ngIf="contentPageDetails != undefined">
                  </div>
                </div>
                <form [formGroup]="profileForm">
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.UserName' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_UserName' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="userName" (focusout)="checkForUniqueUserName()"
                        class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': (profileForm.controls.userName.invalid && (profileForm.controls.userName.dirty || profileForm.controls.userName.touched || isSubmitClicked)),
                      'error-message-hide': !(profileForm.controls.userName.invalid && (profileForm.controls.userName.dirty || profileForm.controls.userName.touched || isSubmitClicked))}">
                        {{(profileForm.controls.userName.errors?.userNameInvalid ?
                        'AccountRegister.UserNameBadWordsErrMsg' : 'AccountRegister.UserNameErrMsg') | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.FirstName' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_FirstName' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="firstName" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.firstName.invalid && (profileForm.controls.firstName.dirty || profileForm.controls.firstName.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.firstName.invalid && (profileForm.controls.firstName.dirty || profileForm.controls.firstName.touched || isSubmitClicked))}">
                        {{'AccountRegister.FirstNameErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.LastName' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_LastName' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="lastName" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.lastName.invalid && (profileForm.controls.lastName.dirty || profileForm.controls.lastName.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.lastName.invalid && (profileForm.controls.lastName.dirty || profileForm.controls.lastName.touched || isSubmitClicked))}">
                        {{'AccountRegister.LastNameErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.EmailAddress' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_EmailAddress' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="emailAddress" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.emailAddress.invalid && (profileForm.controls.emailAddress.dirty || profileForm.controls.emailAddress.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.emailAddress.invalid && (profileForm.controls.emailAddress.dirty || profileForm.controls.emailAddress.touched || isSubmitClicked))}">
                        {{'AccountRegister.EmailAddressErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.JobTitle' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_JobTitle' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="jobTitle" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.jobTitle.invalid && (profileForm.controls.jobTitle.dirty || profileForm.controls.jobTitle.touched || isSubmitClicked),
                        'error-message-hide': !(profileForm.controls.jobTitle.invalid && (profileForm.controls.jobTitle.dirty || profileForm.controls.jobTitle.touched || isSubmitClicked))}">
                        {{'AccountRegister.JobTitleErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.JobRole' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_JobRole' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <select formControlName="jobRole" class="form-control">
                        <option value="">{{'AccountRegister.SelectJobRole' | translate}}</option>
                        <option [value]="item.value" *ngFor="let item of jobRolesList">{{item.name}}</option>
                      </select>
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.jobRole.invalid && (profileForm.controls.jobRole.dirty || profileForm.controls.jobRole.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.jobRole.invalid && (profileForm.controls.jobRole.dirty || profileForm.controls.jobRole.touched || isSubmitClicked))}">
                        {{'AccountRegister.JobRoleErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.Country' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_Country' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <select formControlName="profileCountry" class="form-control">
                        <option value="">{{'AccountRegister.SelectCountry' | translate}}</option>
                        <option [value]="item.value" *ngFor="let item of countryList">{{item.name}}</option>
                      </select>
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.profileCountry.invalid && (profileForm.controls.profileCountry.dirty || profileForm.controls.profileCountry.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.profileCountry.invalid && (profileForm.controls.profileCountry.dirty || profileForm.controls.profileCountry.touched || isSubmitClicked))}">
                        {{'AccountRegister.CountryErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.MobileNumber' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_MobileNumber' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group d-flex align-items-center">
                        <span class="border-end country-code px-2"
                          (click)="showCountryCodeList = true">{{selectedCountryCode?.name}}</span>
                        <input type="text" class="form-control" id="ec-mobile-number" formControlName="mobileNumber" />
                      </div>
                      <div class="mobile-number-code-list mt-2" *ngIf="showCountryCodeList">
                        <div class="px-4 py-2" *ngFor="let item of phoneCodeList" (click)="showCountryCodeList = false">
                          <!-- <img src="/assets/images/down_arrow.png" /> -->
                          <span
                            (click)="selectedCountryCode = item; updateMobileNumberLength(item)">{{item?.name}}</span>
                        </div>
                      </div>
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.mobileNumber.invalid && (profileForm.controls.mobileNumber.dirty || profileForm.controls.mobileNumber.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.mobileNumber.invalid && (profileForm.controls.mobileNumber.dirty || profileForm.controls.mobileNumber.touched || isSubmitClicked))}">
                        {{'AccountRegister.MobileNumberErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.OrganisationName' | translate}}<span
                          class="label-mandatory text-danger">*</span>
                      </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_OrganisationName' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="organisation-input">
                        <input *ngIf="organisationResult?.name" type="text" formControlName="name" class="form-control">
                        <button class="btn btn-primary btn-custom" type="button" (click)="openSearchAddComponent()">{{
                          (!organisationResult?.name ? 'AccountRegister.BtnSearch' : 'AccountRegister.BtnChange') |
                          translate}}</button>
                      </div>
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.name.invalid && (profileForm.controls.name.dirty || profileForm.controls.name.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.name.invalid && (profileForm.controls.name.dirty || profileForm.controls.name.touched || isSubmitClicked))}">
                        {{ (profileForm.controls.name.errors?.required ? 'AccountRegister.OrganisationErrMsgMandatory' :
                        'AccountRegister.OrganisationErrMsg') | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4" *ngIf="organisationResult?.name">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.OrganisationCountry' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_OrganisationCountry' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <select formControlName="country" class="form-control">
                        <option value="">{{'AccountRegister.SelectCountry' | translate}}</option>
                        <option [value]="item.value" *ngFor="let item of countryList">{{item.name}}</option>
                      </select>
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.country.invalid && (profileForm.controls.country.dirty || profileForm.controls.country.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.country.invalid && (profileForm.controls.country.dirty || profileForm.controls.country.touched || isSubmitClicked))}">
                        {{'AccountRegister.OrganisationErrMsgMandatory' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4" *ngIf="organisationResult?.name">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.AddressLine1' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_AddressLine1' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="address1" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.address1.invalid && (profileForm.controls.address1.dirty || profileForm.controls.address1.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.address1.invalid && (profileForm.controls.address1.dirty || profileForm.controls.address1.touched || isSubmitClicked))}">
                        {{'AccountRegister.AddressLine1Errmsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4" *ngIf="organisationResult?.name">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.AddressLine2' | translate}} </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_AddressLine2' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="address2" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.address2.invalid && (profileForm.controls.address2.dirty || profileForm.controls.address2.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.address2.invalid && (profileForm.controls.address2.dirty || profileForm.controls.address2.touched || isSubmitClicked))}">
                        {{'AccountRegister.AddressLine2Errmsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4" *ngIf="organisationResult?.name">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.TownOrCity' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_TownOrCity' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="town" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.town.invalid && (profileForm.controls.town.dirty || profileForm.controls.town.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.town.invalid && (profileForm.controls.town.dirty || profileForm.controls.town.touched || isSubmitClicked))}">
                        {{'AccountRegister.TownOrCityErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="row pb-4" *ngIf="organisationResult?.name">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 label-section">
                      <label class="input-label">{{'AccountRegister.PostCode' | translate}}<span
                          class="label-mandatory text-danger">*</span> </label>
                      <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_PostCode' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <input type="text" formControlName="postCode" class="form-control">
                      <span
                        [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.controls.postCode.invalid && (profileForm.controls.postCode.dirty || profileForm.controls.postCode.touched || isSubmitClicked),
                      'error-message-hide': !(profileForm.controls.postCode.invalid && (profileForm.controls.postCode.dirty || profileForm.controls.postCode.touched || isSubmitClicked))}">
                        {{'AccountRegister.PostCodeErrMsg' | translate}}
                      </span>
                    </div>
                  </div>
                  <div class="pb-4">
                    <div class="d-inline-flex align-items-baseline">
                      <input type="checkbox" id="confirmCheckBox" formControlName="confirmCheckBox">
                      <label class="px-2" for="confirmCheckBox"> {{'AccountRegister.TextConfirmCheckBox' | translate}}
                        <span class="label-mandatory text-danger">*</span> </label>
                    </div>
                    <span
                      [ngClass]="{'error-message text-danger pl-0 pt-2 d-inline-block': profileForm.valid && !profileForm.value.confirmCheckBox && isSubmitClicked,
                    'error-message-hide': !(profileForm.valid && !profileForm.value.confirmCheckBox && isSubmitClicked)}">
                      {{'AccountRegister.TextConfirmCheckBoxErrMsg' | translate}}
                    </span>
                  </div>
                  <div class="d-flex justify-content-center pt-4 form-action-section">
                    <button class="btn btn-primary btn-custom btn-custom-submit" type="button"
                      (click)="submit()">{{'AccountRegister.BtnSubmit' | translate}}</button>
                    <button class="btn btn-primary btn-custom btn-custom-submit" type="button"
                      (click)="cancel()">{{'AccountRegister.BtnCancel' | translate}}</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="margin-bottom-20 margin-top-20 text-center" *ngIf="showLoginPageLink">
              <a class="link-no-decor" [routerLink]="['/']">
                <body-text class="primary-color medium-size">{{'AccountRegister.TextRedirect' | translate}}</body-text>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>