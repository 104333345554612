import { Injectable } from '@angular/core';
import { DownloadService, HttpService } from '@core/shared';
import { map } from 'rxjs/operators';
import { ParamsBuilderService } from '@core/shared/services/params-builder.service';

import {
    UrlBuilder,
    GET_ORGANISATION,
    GET_COUNTRIES,
    GET_JOB_ROLES,
    GET_PHONE_CODES,
    REGISTER_ACCOUNT,
    GET_USERNAME_EXISTS_STATUS
} from '@core/core-api-variables';

@Injectable()
export class AccountRegisterService extends DownloadService {

    constructor(public http: HttpService, public paramsBuilderService: ParamsBuilderService) {
        super();
    }

    accountRegistration(payload) {
        return this.http.post(REGISTER_ACCOUNT, payload).pipe(map((response: any) => {
            return response;
        }));
    }
    getOrganisations(data) {
        let url = UrlBuilder(GET_ORGANISATION, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response?.items ? response.items : [];
        }));
    }
    getPhoneCodes() {
        return this.http.get(GET_PHONE_CODES).pipe(map((response: any) => {
            return response;
        }));
    }
    isDuplicateUserNameCheck(data) {
        const url = UrlBuilder(GET_USERNAME_EXISTS_STATUS, data);
        return this.http.get(url).pipe(map((response: any) => {
            return response;
        }));
    }
    getCountries() {
        return this.http.post(GET_COUNTRIES, {}).pipe(map((response: any) => {
            return response;
        }));
    }
    getJobRoles() {
        return this.http.post(GET_JOB_ROLES, {}).pipe(map((response: any) => {
            return response;
        }));
    }
}
