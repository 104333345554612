<div class="search-add-organisation-section">
    <div class="d-flex justify-content-end pb-3">
        <fa-icon (click)="activeModal.close()" [icon]="faXmarkCircle"></fa-icon>
    </div>
    <div class="d-flex justify-content-center align-items-center pb-4">
        <p class="mb-0 pr-2">{{'AccountRegister.SearchComponentTitle' | translate}}</p>
    </div>
    <div class="d-flex justify-content-end search-add-section align-items-center d-flex-gap-10">
        <p></p>
        <form [formGroup]="searchOrganisationForm">
            <input type="text" formControlName="searchText" class="form-control form-control-custom"
                [ngbTypeahead]="organisationSearch" [resultFormatter]="productFormatter"
                [inputFormatter]="productInputFormatter" />
        </form>
        <div class="d-flex btn-search-add-section d-flex-gap-10">
            <button class="btn btn-primary btn-custom" type="button"
                (click)="searchOrganisation()">{{'AccountRegister.BtnSearch' | translate}}</button>
            <button class="btn btn-primary btn-custom" type="button"
                (click)="addOrganisation()">{{'AccountRegister.BtnAddNew' | translate}}</button>
        </div>
    </div>
    <div class="mt-4 p-3 result-section">
        <p class="px-3" *ngIf="!showAddOrganisationForm && !showSearchOrganisationInfo"> {{'AccountRegister.TextNoResult' |
            translate}}</p>
        <form class="px-5" [formGroup]="createOrganisationForm"
            *ngIf="showAddOrganisationForm || showSearchOrganisationInfo"
            [ngClass]="{'show-search-mobile-view': showSearchOrganisationInfo}">
            <div class="row pb-4">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.OrganisationName' | translate}} <span
                            class="label-mandatory text-danger">*</span>
                    </label>
                    <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_OrganisationName' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <input type="text" *ngIf="showAddOrganisationForm" formControlName="name" class="form-control">
                        <span
                            [ngClass]="{'d-none': !showAddOrganisationForm, 'error-message text-danger pl-0 pt-2 d-inline-block': showAddOrganisationForm && createOrganisationForm.controls.name.invalid && (createOrganisationForm.controls.name.dirty || createOrganisationForm.controls.name.touched || isSubmitClicked),
                        'error-message-hide': showAddOrganisationForm && !(createOrganisationForm.controls.name.invalid && (createOrganisationForm.controls.name.dirty || createOrganisationForm.controls.name.touched || isSubmitClicked))}">
                            {{'AccountRegister.OrganisationErrMsg' | translate}}
                        </span>
                    </div>
                    <label *ngIf="showSearchOrganisationInfo">{{searchOrganisationInfo.name}}</label>
                </div>
            </div>
            <!-- <div class="row pb-4" *ngIf="!showSearchOrganisationInfo">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.OrganisationCode' | translate}}<span
                            class="label-mandatory text-danger">*</span> </label>
                    <fa-icon ngbTooltip="Organisation Name" [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <input type="text" *ngIf="showAddOrganisationForm" formControlName="code" class="form-control">
                        <span class="error-message text-danger pl-0 pt-2 d-inline-block" *ngIf="showAddOrganisationForm && createOrganisationForm.controls.code.invalid && 
                            (createOrganisationForm.controls.code.dirty || 
                            createOrganisationForm.controls.code.touched || isSubmitClicked)">
                            {{'AccountRegister.OrganisationCodeErrMsg' | translate}}
                        </span>
                    </div>
                </div>
            </div> -->
            <div class="row pb-4" *ngIf="!showSearchOrganisationInfo">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.Country' | translate}} <span
                            class="label-mandatory text-danger">*</span> </label>
                    <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_OrganisationCountry' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <select formControlName="country" class="form-control">
                            <option value="">{{'AccountRegister.SelectCountry' | translate}}</option>
                            <option [value]="item.value" *ngFor="let item of countryList">{{item.name}}</option>
                        </select>
                        <span
                            [ngClass]="{'d-none': !showAddOrganisationForm, 'error-message text-danger pl-0 pt-2 d-inline-block': showAddOrganisationForm && showAddOrganisationForm && createOrganisationForm.controls.country.invalid && (createOrganisationForm.controls.country.dirty || createOrganisationForm.controls.country.touched || isSubmitClicked),
                        'error-message-hide': showAddOrganisationForm && !(createOrganisationForm.controls.country.invalid && (createOrganisationForm.controls.country.dirty || createOrganisationForm.controls.country.touched || isSubmitClicked))}">
                            {{'AccountRegister.CountryErrMsg' | translate}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.AddressLine1' | translate}} <span
                            class="label-mandatory text-danger">*</span> </label>
                    <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_AddressLine1' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <input *ngIf="showAddOrganisationForm" type="text" formControlName="address1"
                            class="form-control">
                        <span
                            [ngClass]="{'d-none': !showAddOrganisationForm, 'error-message text-danger pl-0 pt-2 d-inline-block': showAddOrganisationForm && createOrganisationForm.controls.address1.invalid && (createOrganisationForm.controls.address1.dirty || createOrganisationForm.controls.address1.touched || isSubmitClicked),
                        'error-message-hide': showAddOrganisationForm && !(createOrganisationForm.controls.address1.invalid && (createOrganisationForm.controls.address1.dirty || createOrganisationForm.controls.address1.touched || isSubmitClicked))}">
                            {{'AccountRegister.AddressLine1Errmsg' | translate}}
                        </span>
                    </div>
                    <label *ngIf="showSearchOrganisationInfo">{{searchOrganisationInfo.address1}}</label>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.AddressLine2' | translate}}</label>
                    <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_AddressLine2' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <input type="text" *ngIf="showAddOrganisationForm" formControlName="address2"
                            class="form-control">
                        <span
                            [ngClass]="{'d-none': !showAddOrganisationForm, 'error-message text-danger pl-0 pt-2 d-inline-block': showAddOrganisationForm && createOrganisationForm.controls.address2.invalid && (createOrganisationForm.controls.address2.dirty || createOrganisationForm.controls.address2.touched || isSubmitClicked),
                        'error-message-hide': showAddOrganisationForm && !(createOrganisationForm.controls.address2.invalid && (createOrganisationForm.controls.address2.dirty || createOrganisationForm.controls.address2.touched || isSubmitClicked))}">
                            {{'AccountRegister.AddressLine2Errmsg' | translate}}
                        </span>
                    </div>
                    <label *ngIf="showSearchOrganisationInfo">{{searchOrganisationInfo.address2}}</label>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.TownOrCity' | translate}}<span
                            class="label-mandatory text-danger">*</span> </label>
                    <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_TownOrCity' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <input *ngIf="showAddOrganisationForm" type="text" formControlName="town" class="form-control">
                        <span
                            [ngClass]="{'d-none': !showAddOrganisationForm, 'error-message text-danger pl-0 pt-2 d-inline-block': showAddOrganisationForm && createOrganisationForm.controls.town.invalid && (createOrganisationForm.controls.town.dirty || createOrganisationForm.controls.town.touched || isSubmitClicked),
                        'error-message-hide': showAddOrganisationForm && !(createOrganisationForm.controls.town.invalid && (createOrganisationForm.controls.town.dirty || createOrganisationForm.controls.town.touched || isSubmitClicked))}">
                            {{'AccountRegister.TownOrCityErrMsg' | translate}}
                        </span>
                    </div>
                    <label *ngIf="showSearchOrganisationInfo">{{searchOrganisationInfo.town}}</label>
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12 label-section">
                    <label class="input-label">{{'AccountRegister.PostCode' | translate}}<span
                            class="label-mandatory text-danger">*</span> </label>
                    <fa-icon ngbTooltip="{{'AccountRegister.TOOLTIP_PostCode' | translate}}"
                        [icon]="faInfoCircle"></fa-icon>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 px-0 col-xs-12">
                    <div class="d-block">
                        <input *ngIf="showAddOrganisationForm" type="text" formControlName="postCode"
                            class="form-control">
                        <span
                            [ngClass]="{'d-none': !showAddOrganisationForm, 'error-message text-danger pl-0 pt-2 d-inline-block': showAddOrganisationForm && createOrganisationForm.controls.postCode.invalid && (createOrganisationForm.controls.postCode.dirty || createOrganisationForm.controls.postCode.touched || isSubmitClicked),
                        'error-message-hide': showAddOrganisationForm && !(createOrganisationForm.controls.postCode.invalid && (createOrganisationForm.controls.postCode.dirty || createOrganisationForm.controls.postCode.touched || isSubmitClicked))}">
                            {{'AccountRegister.PostCodeErrMsg' | translate}}
                        </span>
                    </div>
                    <label *ngIf="showSearchOrganisationInfo">{{searchOrganisationInfo.postCode}}</label>
                </div>
                <div class="d-flex align-items-center justify-content-center pt-4"
                    *ngIf="showAddOrganisationForm || showSearchOrganisationInfo">
                    <button class="btn btn-primary btn-custom" type="button" (click)="submit()">{{
                        (showAddOrganisationForm ? 'AccountRegister.BtnSubmit' : 'AccountRegister.BtnSelect') |
                        translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>