import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@core/shared/shared.module';

import {
    AccountActivationComponent,
    ActivateUserComponent,
    CompleteProfileComponent,
    CompleteProfileSelfRegistrationComponent,
    AccountRegisterComponent,    
    TermsConditionsComponent,
    ExpiredPasswordComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    SiteMonitoringComponent,
    PreloginLegalPageComponent
} from './accounts';

import { PreloginService } from './services';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrganisationSearchAddComponent } from './accounts/account-register/organisation-search-add/organisation.search.add.component';
import { AccountRegisterService } from './accounts/account-register/services/account-register.services';
import { AccountRegisterConfirmationModalComponent } from './accounts/account-register/confirmation-modal/confirmation.modal.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        TranslateModule,
        NgbTooltipModule,
        FontAwesomeModule,
        SharedModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        PreloginService,
        AccountRegisterService,
        { provide: Window, useValue: window }
    ],
    declarations: [
        AccountActivationComponent,
        ActivateUserComponent,
        AccountRegisterConfirmationModalComponent,
        CompleteProfileComponent,
        CompleteProfileSelfRegistrationComponent,
        AccountRegisterComponent,
        TermsConditionsComponent,
        ExpiredPasswordComponent,
        ForgotPasswordComponent,
        LoginComponent,
        ResetPasswordComponent,
        SiteMonitoringComponent,
        PreloginLegalPageComponent,
        OrganisationSearchAddComponent
    ]
})
export class PreloginModule { }